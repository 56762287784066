<template>
  <div class="cookie-modal" v-if="!haveSetCookiePolicy">
    <div class="cookie-modal-content">
      <p class="cookie-modal-message">
        กรมส่งเสริมอุตสาหกรรมสังกัดกระทรวงอุตสาหกรรมมีการใช้งานคุกกี้ (Cookies)
        เพื่อจัดการข้อมูลส่วนบุคคลและ
        ช่วยเพิ่มประสิทธิภาพการใช้งานเว็บไซต์ท่านสามารถศึกษารายละเอียดการใช้งานการตั้งค่าคุกกี้ได้ที่<br />
        <a
          v-on:click="GoToCookiesPolicy"
          target="_blank"
          rel="noopener noreferrer"
          class="text-decoration-none cursor-pointer"
          >นโยบายการใช้คุกกี้</a
        >
        คลิกปุ่ม "ตกลง" เพื่อยอมรับคุกกี้
        <button
          @click="SetCookiePolicy()"
          class="btn btn-sm btn-info"
          type="button"
        >
          ตกลง
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import Cookie from "../cookie/cookie.vue";
import VueCookies from "vue-cookies";

export default {
  components: { Cookie },
  name: "CookiePolicy",
  props: {},
  data() {
    return {
      haveSetCookiePolicy: false,
    };
  },
  mounted() {
    //check have set cookiePilycy
    this.haveSetCookiePolicy =
      VueCookies.get("CookiePolicy") == 1 ? true : false;
    console.log("cookie : ", VueCookies.get("CookiePolicy"));
  },
  methods: {
    SetCookiePolicy() {
      VueCookies.set("CookiePolicy", 1);
      this.haveSetCookiePolicy = true;
    },
    GoToCookiesPolicy() {
      this.$router.push({ path: "/CookiePolicy" });
    },
  },
};
</script>

<style scoped>
.cookie-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(241, 241, 241, 0.8);
  padding: 10px;
  text-align: center;
  z-index: 9999;
}

.cookie-modal-content {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.cookie-modal-content p.cookie-modal-message {
  font-size: 16px;
}
</style>
