<template>
  <section class="our-integration ptb-120 bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12">
          <div class="section-heading" data-aos="fade-up">
            <h2>หมวดหมู่</h2>
            <p>
            </p>
          </div>
        </div>
      </div>
      <div class="position-relative w-100">
        <div class="row">
          <div class="col-lg-4 col-md-6" v-for="(item, index) in CategoryItem" :key="index">
            <div v-on:click="GoToViewAllNews(item.Id)"
              class="cursor-cus position-relative text-decoration-none connected-app-single bg-white border border-2 transition-base rounded-custom d-block overflow-hidden p-5"
              :class=" index == 0 ? 
                        'mt-0 mt-lg-0 mt-md-0' :
                        index == 1 ? 
                        'mt-4 mt-lg-0 mt-md-0' :
                        index == 2 ?
                        'mt-4 mt-lg-0' :
                        'mt-4' "
            >
              <div class="text-center position-relative connected-app-content">
                <div class="integration-logo bg-blue-light rounded-circle p-4 d-inline-block">
                  <img
                    :src ="item.IconPath"
                    width="60"
                    alt="integration"
                    class="img-fluid height-custom"
                  />
                </div>
                
                <h5></h5>
                <p class="mb-0 text-muted">
                  {{item.Name}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
</template>

<style>
.padding-div {
  margin-bottom: 20px;
}
.cursor-cus{
  cursor: pointer;
}
.bg-blue-light{
  background-color: #0c60ff;
}
.height-custom{
  height: 65px !important;
}
</style>

<script>
import store from "../../store";

export default {
  name: 'Cate',
  data(){
    return{
      CategoryItem: [],
    }
  },
  mounted(){
    this.SetDefault();
    this.LoadCateById();
    

  },
  methods: {
    SetDefault(){
      this.CategoryItem = [];
      store.commit('SetNewSubCateId', 0);
    },
    LoadCateById(){
      const typeId = store.getters.NewsDetail.Type
      const cateData = store.getters.CategoryData
      this.CategoryItem = cateData.filter(e => e.RelationWebId == typeId)

      // console.log('typeId', typeId);
      // console.log('cateData', cateData);
      // console.log('cateItem', this.CategoryItem);
    },
    GoToViewAllNews(id){
      const item = this.CategoryItem.filter(e => e.Id == id)
      // console.log('item', item);
      store.commit('SetNewsCateId', id);
      if(item[0].HasChild){
        this.$router.push({ name: 'SubCategory' });
      }
      else{
        this.$router.push({ name: 'News' });
      }
    },
  }
};
</script>
