<template>
  <div class="main-wrapper">
    <Navbar />
    <SectionAbout />
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/footer/Footer.vue";
import Navbar from "../../components/nav/Navbar.vue";
import SectionAbout from "../../components/MainComponents/SectionAbout.vue";

export default {
  components: { Navbar, SectionAbout, Footer },
};
</script>
