<template>
  <div class="main-wrapper">
    <Navbar />
    <BreadCrumb :title="this.newsData.Title" />
    <NewsSingle
      :details="modifyUrl(this.newsData.Text)"
      :attactes="this.newsData.AttachFilesName"
    />
    <Footer />
  </div>
</template>

<script>
import BreadCrumb from "../../components/common/BreadCrumb.vue";
import CtaTwo from "../../components/cta/CtaTwo.vue";
import Footer from "../../components/footer/Footer.vue";
import Navbar from "../../components/nav/Navbar.vue";
import LatestNews from "../../components/news/LatestNews.vue";
import NewsSingle from "../../components/news/NewsSingle.vue";
import axios from "axios";
import store from "../../store";

const GetNewByIdURI = `${store.getters.URL}/NewsAndActivity/get-news-by-id`;
export default {
  components: { Navbar, NewsSingle, BreadCrumb, LatestNews, Footer, CtaTwo },
  data() {
    return {
      id: store.getters.NewsDetail.Id,
      newsData: "",
    };
  },
  created() {},
  mounted() {
    this.getNewsById();
  },
  methods: {
    getNewsById: function () {
      axios
        .get(`${GetNewByIdURI}?id=${this.id}`)
        .then((response) => {
          const responseData = response.data.Data;
          // console.log(responseData);
          this.newsData = responseData;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    modifyUrl(url) {
      let endpoint = url;
      if (endpoint) {
        endpoint = endpoint.replace("oembed", "iframe");
        endpoint = endpoint.replace("url=", "src=");
        endpoint = endpoint.replace("watch?v=", "embed/");
      }

      return endpoint;
    },
  },
  computed: {},
  watch: {
    $route() {
      this.Id = this.$route.params.id || "";
      this.getNewsById();
    },
  },
};
</script>
