<template>
  <section
    class="page-header position-relative overflow-hidden ptb-120 bg-dark"
  >
    <div class="container">
      <div class="row justify-content">
        <div class="col-lg-8 col-md-12">
          <div class="section-heading">
            <h1 class="display-5 fw-bold">{{title}}</h1>
            <h1 class="display-5 fw-bold">{{subTitle}}</h1>
            <!-- <p class="lead mb-0">
              {{subTitle}}
            </p> -->
          </div>
        </div>
      </div>
      <div
        class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5"
      ></div>
    </div>
  </section>
</template>

<script>
import store from "../../store";
import { useStore } from "vuex";

const newsDetail = store.getters.NewsDetail;

export default {
  mounted(){
    this.SetDefault();
    this.CheckTitle();
    this.CheckParamId();
  },
  data(){
    return {
      title: null,
      subTitle: null
    }
  },
  methods:{
    SetDefault(){
      this.title = null;
      this.subTitle = null;
    },
    CheckParamId(){
      if(newsDetail.Type == 0 && (this.$route.params.textSearch != null || this.$route.params.textSearch != undefined || this.$route.params.textSearch !=""))
      {
        this.title = "ระบบค้นหาเว็บไซต์";
        this.subTitle = "";
      }
    },
    GetTitle(data){
      let tempTitle = data.Name.split('(');
      this.title = tempTitle[0];

      if(tempTitle.length > 1){
        this.subTitle = `(${tempTitle[1]}`;
      }
    },
    CheckLevelId(){
      let count = 0;
      if(newsDetail.Type > 0){ count++ }
      if(newsDetail.CateId > 0){ count++ }
      if(newsDetail.SubCateId > 0){count++ }
      return count;
    },
    CheckTitle(){
      // console.log('type', newsDetail);
      // console.log('CheckLevelId', this.CheckLevelId());
      if(newsDetail.Type == 0)
      {
        return;
      }
      const subWebSite = store.getters.SubWebSite;
      const categoryData = store.getters.CategoryData;
      const level = this.CheckLevelId();
      //console.log('level', level)
      if(level == 1){
        let itemData = subWebSite.find( ({ Id }) => Id === newsDetail.Type );
        this.GetTitle(itemData)
      }
      else if(level == 2){
        let itemData = categoryData.find( ({ Id }) => Id === newsDetail.CateId );
        //console.log('itemData', itemData)
        this.GetTitle(itemData)
      }
      else
      {
        let itemData = (categoryData.find( ({ Id }) => Id === newsDetail.CateId ).Childs).find( ({ Id }) => Id === newsDetail.SubCateId );
        console.log('itemData', itemData)
        this.GetTitle(itemData)
      }
    }
  },
  watch: {
    '$route'() {
      this.SetDefault();
      this.CheckTitle();
      this.CheckParamId();
    },
  },
};
</script>
