<template>
  <footer id="footer-section" class="footer-section">
    <!--footer top start-->
    <!--for light footer add .footer-light class and for dark footer add .bg-dark .text-white class-->
    <div
      class="footer-top ptb-120 custom-footer"
      :class="footerDark ? 'bg-dark text-white' : 'footer-light'"
    >
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-md-8 col-lg-5 mb-md-4 mb-lg-0">
            <div
              class="footer-single-col"
              style="display: inline-blockว margin-top: -15px"
            >
              <div class="footer-single-col mb-4">
                <img
                  src="@/assets/img/logos/logo_dip_v.png"
                  alt="logo"
                  class="img-fluid logo-white"
                  width="250"
                />
                <img
                  src="@/assets/img/logos/logo_dip_v.png"
                  alt="logo"
                  class="img-fluid logo-color"
                  width="250"
                />
              </div>
            </div>
            <div style="display: inline-block; margin-top: -30px">
              <h4 style="color: #7f2c8b; margin: 0px">
                {{ $t("message.companyName") }}
              </h4>
              <div>
                <p>
                  {{ $t("message.address") }} <br />
                  <strong>{{ $t("message.tel") }} </strong> 02-430-6860
                  <strong>{{ $t("message.fax") }} </strong> 02-354-3299
                </p>

                <!-- <span
                  ><strong>{{ $t("message.tel") }} </strong> 02-430-6860
                </span>
                <span
                  ><strong>{{ $t("message.fax") }} </strong> 02-354-3299
                </span> -->
              </div>
            </div>
            <div class="mt-4">
              <h6 class="mb-0 py-2">{{ $t("message.contactUs") }}</h6>
              <div class="footer-single-col text-start">
                <ul class="list-unstyled list-inline mb-0">
                  <li class="list-inline-item">
                    <a
                      href="https://www.facebook.com/dipromindustry"
                      target="_blank"
                      ><img
                        src="@/assets/img/social/facebook.png"
                        class="custom-social-img"
                    /></a>
                  </li>
                  <li class="list-inline-item">
                    <a href="https://twitter.com/dipindustry" target="_blank"
                      ><img
                        src="@/assets/img/social/twitter.png"
                        class="custom-social-img"
                    /></a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="https://www.youtube.com/channel/UCqSlyUZ4Mlu8oDPz0cRKSig"
                      target="_blank"
                      ><img
                        src="@/assets/img/social/youtube.png"
                        class="custom-social-img"
                    /></a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="https://www.instagram.com/dipromindustry"
                      target="_blank"
                      ><img
                        src="@/assets/img/social/instagram.png"
                        class="custom-social-img"
                    /></a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="https://page.line.me/diprom?openQrModal=true"
                      target="_blank"
                      ><img
                        src="@/assets/img/social/line.png"
                        class="custom-social-img"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="ratting-wrap mt-4">
              <h6 class="mb-0">10/10 {{ $t("message.rating") }}</h6>
              <rating />
            </div>
          </div>
          <div class="col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0">
            <div class="row">
              <div class="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                <div class="footer-single-col">
                  <h3>SITE MAP</h3>
                  <ul class="list-unstyled footer-nav-list mb-lg-0">
                    <li>
                      <router-link to="/" class="text-decoration-none">{{
                        $t("message.homepage")
                      }}</router-link>
                    </li>
                    <li>
                      <a
                        href="#adviseSection"
                        @click="checkCurrentUrl('#adviseSection')"
                        class="text-decoration-none"
                      >
                        {{ $t("message.userManual") }}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#ServiceSction"
                        @click="checkCurrentUrl('#ServiceSction')"
                        class="text-decoration-none"
                      >
                        {{ $t("message.service") }}
                      </a>
                    </li>
                    <li>
                      <router-link to="/AboutUs" class="text-decoration-none">{{
                        $t("message.about")
                      }}</router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <div class="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                <div class="footer-single-col">
                  <h3>SERVICE</h3>
                  <ul class="list-unstyled footer-nav-list mb-lg-0">
                    <li>
                      <a
                        v-on:click="GolbalGoToViewAllNews(4)"
                        class="text-decoration-none"
                        style="cursor: pointer"
                        >{{ $t("system.cousultingService") }}</a
                      >
                    </li>
                    <li>
                      <a
                        v-on:click="GolbalGoToViewAllNews(3)"
                        class="text-decoration-none"
                        style="cursor: pointer"
                        >{{ $t("system.trainingService") }}</a
                      >
                    </li>
                    <li>
                      <a
                        v-on:click="GolbalGoToViewAllNews(5)"
                        class="text-decoration-none"
                        style="cursor: pointer"
                        >{{ $t("system.fundingService") }}</a
                      >
                    </li>
                    <li>
                      <a
                        v-on:click="GolbalGoToViewAllNews(6)"
                        class="text-decoration-none"
                        style="cursor: pointer"
                        >{{ $t("system.matchingService") }}</a
                      >
                    </li>
                    <li>
                      <a
                        v-on:click="GolbalGoToViewAllNews(7)"
                        class="text-decoration-none"
                        style="cursor: pointer"
                        >{{ $t("system.onlineMarket") }}</a
                      >
                    </li>
                    <li>
                      <a
                        v-on:click="GolbalGoToViewAllNews(8)"
                        class="text-decoration-none"
                        style="cursor: pointer"
                        >{{ $t("system.projectService") }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div> -->
              <div class="col-md-8 col-lg-8 mt-4 mt-md-0 mt-lg-0">
                <div class="footer-single-col">
                  <h3>CONTACT US</h3>
                  <div class="footer-nav-list mb-lg-0">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15501.047360660878!2d100.51743630604543!3d13.763077127875905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29eaca7942595%3A0xfe26a17330d38175!2z4LiB4Lij4Lih4Liq4LmI4LiH4LmA4Liq4Lij4Li04Lih4Lit4Li44LiV4Liq4Liy4Lir4LiB4Lij4Lij4LihICjguJTguLXguJ7guKPguYnguK3guKEp!5e0!3m2!1sth!2sth!4v1670052129883!5m2!1sth!2sth"
                      width="100%"
                      height="340"
                      style="border: 0"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <br />
                    <!-- <p>ผู้เข้าชม รายเดือน ... ราย</p>
                    <p>ผู้เข้าชม สะสม ... ราย</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--footer top end-->

    <!--footer bottom start-->
    <div class="footer-bottom text-white py-4 bg-dark">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-5 col-lg-5">
            <div class="copyright-text">
              <p class="mb-lg-0 mb-md-0" style="font-size: 15px !important">
                {{ $t("message.copyRight") }}
              </p>
            </div>
          </div>
          <div class="col-md-7 col-lg-7">
            <div
              class="footer-single-col text-start text-lg-end text-md-end"
            ></div>
            <div class="copyright-text">
              <p class="text-lg-end mb-lg-0 mb-md-0">
                PDPA :
                <a
                  v-on:click="GoToPapd"
                  target="_blank"
                  class="text-decoration-none cursor-pointer"
                  >{{ $t("message.pdpa") }}</a
                >
                |
                <a
                  v-on:click="GoToPrivacyPolicy"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-decoration-none cursor-pointer"
                  >{{ $t("message.privacyPolicy") }}</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--footer bottom end-->

    <!-- begin::cookie -->

    <CookiePolicy></CookiePolicy>

    <!-- end::cookie -->
  </footer>
</template>
<style scoped>
.custom-footer {
  background: url("../../assets/img/page-header-bg.svg") no-repeat bottom right;
  padding: 80px 0 40px !important;
}
.custom-social {
  width: 40px;
  height: 40px;
  line-height: 36px;
}
.custom-social-img {
  width: 40px;
  height: 40px;
}
</style>
<script
  type="text/javascript"
  src="https://free-hit-counters.net/count/av24"
></script>
<script
  type="text/javascript"
  src="https://www.whomania.com/ctr?id=eddd31e3cc0de7156dcd82692c7c0c2d36070269"
></script>
<script>
import Rating from "../common/Rating.vue";
import CookiePolicy from "../cookie/cookie.vue";
// import pdf from "../../assets/pdf/pdpa.pdf";
// Helpers
//import { useCookies } from "vue3-cookies";
//import VueCookies from "vue-cookies";

export default {
  components: { Rating, CookiePolicy },
  name: "Footer",
  props: {
    footerDark: Boolean,
    //pdfLink : require('../../assets/pdf/pdpa.pdf')
  },
  data() {
    return {
      //haveSetCookiePolicy: false,
    };
  },
  mounted() {
    //check have set cookiePilycy
    // this.haveSetCookiePolicy =
    //   VueCookies.get("CookiePolicy") == 1 ? true : false;
    // console.log("cookie : ", VueCookies.get("CookiePolicy"));
  },
  methods: {
    openPdf() {
      //window.open('../../assets/pdf/pdpa.pdf', '_blank')
      //console.log(require('../../assets/pdf/pdpa.pdf'));
    },
    GoToPapd() {
      this.$router.push({ name: "Pdpa" });
    },
    GoToPrivacyPolicy() {
      this.$router.push({ name: "PrivacyPolicy" });
    },
    // SetCookiePolicy() {
    //   VueCookies.set("CookiePolicy", 1);
    //   this.haveSetCookiePolicy = true;
    // },
    // GoToCookiesPolicy() {
    //   this.$router.push({ path: "/CookiePolicy" });
    // },
  },
};
</script>
