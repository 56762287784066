<template>
  <section class="blog-details ptb-120">
    <div class="container">
      <div class="row justify-content-center align-items-center mt-5">
        <div v-html="details"></div>
        <template v-if="attactes && attactes.length > 0">
          <div class="" v-for="(attacte, index) in attactes" :key="index">
            <div
              class="app-two-single-feature d-md-flex align-items-start mb-30"
            >
              <div
                class="app-two-single-feature-icon box-four me-3 mb-4 mb-md-0"
              >
                <div class="app-two-single-feature-content">
                  <h3 class="h5">เอกสารแนบ/เอกสารดาวน์โหลด:</h3>
                </div>
              </div>
              <a :href="attacte.Url" target="_blank" rel="noopener noreferrer">
                <img
                  alt=""
                  style="height: 70px; width: 70px"
                  src="media/svg/files/documents.png"
                />
              </a>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<style>
/* workaround width image */
figure.image img {
  width: 100%;
}

/* workaround width and height youtube iframe */
figure.media iframe {
  width: 100%;
  height: 500px;
}
</style>

<script>
export default {
  props: {
    details: String,
    attactes: [],
  },
};
</script>
