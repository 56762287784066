<template>
  <div class="main-wrapper">
    <Navbar />
    <NewsHeader/>
    <CategoryItems/>
    <Footer/>
  </div>
</template>

<script>
import Footer from "../../../components/footer/Footer.vue";
import Navbar from "../../../components/nav/Navbar.vue";
import NewsHeader from "../../../components/news/NewsHeader.vue";
import CategoryItems from "../../../components/category/CategoryItems.vue";

export default {
  components: { 
    Navbar,
    NewsHeader, 
    CategoryItems,
    Footer 
  },
};
</script>