<template>
  <header
    class="main-header w-100 z-10"
    :class="darkNav ? 'position-absolute' : ''"
  >
    <nav
      class="navbar navbar-expand-xl sticky-header"
      :class="[
        darkNav ? 'navbar-dark' : 'navbar-light',
        isSticky ? 'affix' : '',
      ]"
    >
      <div
        class="container d-flex align-items-center justify-content-lg-between position-relative"
      >
        <router-link
          to="/"
          class="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
        >
          <img
            src="@/assets/img/logos/logo_dip_v.png"
            alt="logo"
            class="img-fluid logo-white"
            style="width: 120px"
          />
          <img
            src="@/assets/img/logos/logo_dip_v.png"
            alt="logo"
            class="img-fluid logo-color"
            style="width: 120px"
          />
        </router-link>

        <router-link
          class="navbar-toggler position-absolute right-0 border-0"
          to="#offcanvasWithBackdrop"
          role="button"
        >
          <span
            class="far fa-bars"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBackdrop"
            aria-controls="offcanvasWithBackdrop"
          ></span>
        </router-link>

        <div class="collapse navbar-collapse justify-content-center">
          <ul class="nav col-12 col-md-auto justify-content-center main-menu">
            <li class="nav-item dropdown">
              <router-link to="/" class="nav-link">{{
                $t("message.homepage")
              }}</router-link>
            </li>
            <li>
              <a href="#adviseSection" @click="checkCurrentUrl('#adviseSection')" class="nav-link"> {{ $t("message.userManual") }} </a>
            </li>
            <li>
              <a href="#ServiceSction" @click="checkCurrentUrl('#ServiceSction')" class="nav-link">
                {{ $t("message.service") }}
              </a>
            </li>
            <li>
              <router-link to="/AboutUs" class="nav-link">{{ $t("message.about") }}</router-link>
            </li>
            <li>
              <a href="#footer-section" class="nav-link"> {{ $t("message.contactUs") }} </a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ $t("message.changeLanguage") }}
              </a>
              <div
                class="dropdown-menu border-0 rounded-custom shadow py-0 bg-white"
              >
                <div
                  class="dropdown-grid rounded-custom width-half"
                  style="width: 200px !important "
                >
                  <div class="dropdown-grid-item">
                    <h6 class="drop-heading">
                      {{ $t("message.changeLangText") }}
                    </h6>
                    <a
                      v-on:click="OnChangeLanguage('th')"
                      class="dropdown-link cursor-pointer"
                    >
                      <span
                        class="demo-list bg-th rounded text-white fw-bold"
                      ></span>
                      <div class="dropdown-info">
                        <div class="drop-title">{{ $t("lang.thai") }}</div>
                      </div>
                    </a>
                    <a
                      v-on:click="OnChangeLanguage('en')"
                      class="dropdown-link cursor-pointer"
                    >
                      <span
                        class="demo-list bg-us rounded text-white fw-bold"
                      ></span>
                      <div class="dropdown-info">
                        <div class="drop-title">{{ $t("lang.english") }}</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <!-- <li>
              <a
                class="nav-link"
                v-on:click="CallSwal()"
                target="_blank"
                style="cursor: pointer"
              >
                {{ $t("message.register") }}
              </a>
            </li>
            <li>
              <a
                class="nav-link"
                :href="customerUrl"
                target="_blank"
              >
                {{ $t("message.login") }}
              </a>
            </li> -->
          </ul>
        </div>
        <div
          class="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block"
        >
          <a
            v-on:click="CallSwal()"
            target="_blank"
            class="btn btn-link btn-sm text-decoration-none me-2"
            >{{ $t("message.register") }}</a
          >
          <a
            :href="customerUrl"
            target="_blank"
            class="btn btn-primary btn-sm"
            >{{ $t("message.login") }}</a
          >
        </div>
        <!-- <div
          class="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block"
        >
          <div>
            <img
              src="@/assets/img/logos/icon-call.png"
              alt="logo"
              class="img-fluid logo-color"
              style="width: 113px;"
            />
          </div>
          <div style="padding-top: 5px;">
            <i class="fa-regular fa-globe"></i>
            <select 
              class="form-select-sm"
              style="cursor: pointer; border: 0px; outline: none !important;"
              v-model="$i18n.locale"
              @change="$i18n.locale = $i18n.locale"
            >
              <option value="th">ภาษาไทย</option>
              <option value="en">English</option>
            </select>
          </div>
        </div> -->
        <Offcanvas />
      </div>
    </nav>
  </header>
</template>
<script>
import { Controller } from 'swiper';
import store from "../../store";
import Offcanvas from "./Offcanvas.vue";
export default {
  components: { Offcanvas },
  name: "Navbar",
  props: {
    darkNav: String,
  },
  data() {
    return {
      isSticky: false,
      locale: "th",
      customerUrl: store.getters.CustomerUrl,
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 80) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    });
  },
  methods: {
    GoToViewAllNews(typeId) {
      store.commit("SetNewsDetail", typeId);
      if (typeId == 2) {
        this.$router.push({ name: "Category" });
      } else {
        this.$router.push({ name: "News" });
      }
    },
  },
};
</script>
