<template>
  <section class="faq-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-12">
          <div class="section-heading mb-5 text-center">
            <img
              src="@/assets/img/logos/logo_dip_v.png"
              alt="logo"
              class="img-fluid logo-color"
              width="350"
            />
            <h5 class="h6 text-primary">บริการดีพร้อม (DIPROM E-Services)</h5>
            <h2>กรมส่งเสริมอุตสาหกรรม</h2>
            <p>
              นโยบายการคุ้มครองข้อมูลส่วนบุคคล(Privacy Policy)
              ของระบบบริการดีพร้อม (DIPROM E-Services)
            </p>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-10 col-12">
          <div class="accordion faq-accordion" id="accordionExample">
            <div class="accordion-item border border-2 active">
              <h5 class="accordion-header" id="faq-1">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-1"
                  aria-expanded="true"
                >
                  ระบบบริการดีพร้อม (DIPROM E-Services)
                </button>
              </h5>
              <div
                id="collapse-1"
                class="accordion-collapse collapse show"
                aria-labelledby="faq-1"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  &nbsp;&nbsp;กรมส่งเสริมอุตสาหกรรม (ต่อไปในนโยบายนี้เรียกว่า
                  “กสอ.”)
                  ตระหนักถึงความสำคัญของข้อมูลส่วนบุคคลและข้อมูลอื่นอันเกี่ยวกับท่าน
                  (รวมเรียกว่า “ข้อมูล”) เพื่อให้ท่านสามารถเชื่อมั่นได้ว่า กสอ.
                  มีความโปร่งใสและความรับผิดชอบในการเก็บรวบรวม
                  ใช้หรือเปิดเผยข้อมูลของท่าน
                  ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
                  (“กฎหมายคุ้มครองข้อมูลส่วนบุคคล”)
                  รวมถึงกฎหมายอื่นที่เกี่ยวข้อง นโยบายการคุ้มครองข้อมูลส่วนบุคคล
                  (“นโยบาย”) นี้
                  จึงได้ถูกจัดทำขึ้นเพื่อชี้แจงแก่ท่านถึงรายละเอียดเกี่ยวกับ
                  การเก็บรวบรวม ใช้หรือเปิดเผย (รวมเรียกว่า “ประมวลผล”)
                  ข้อมูลส่วนบุคคลซึ่งดำเนินการโดย กสอ.
                  รวมถึงเจ้าหน้าที่และบุคคลที่เกี่ยวข้องผู้ดำเนินการแทนหรือในนามของ
                  กสอ. โดยมีเนื้อหาสาระดังต่อไปนี้
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-2">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-2"
                  aria-expanded="false"
                >
                  วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล
                </button>
              </h5>
              <div
                id="collapse-2"
                class="accordion-collapse show"
                aria-labelledby="faq-2"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    1) เพื่อดำเนินการตามที่จำเป็นในการดำเนินประโยชน์สาธารณะที่
                    กสอ. ได้รับมอบหมายให้สำเร็จลุล่วง
                    หรือเป็นการจำเป็นเพื่อใช้อำนาจทางกฎหมายที่ กสอ.
                    มีอำนาจหน้าที่ในการดำเนินการตามพันธกิจดังปรากฏใน
                    กฎกระทรวงแบ่งส่วนราชการกรมส่งเสริมอุตสาหกรรม
                    กระทรวงอุตสาหกรรม พ.ศ. 2560 และกฎหมาย กฎ
                    ระเบียบหรือคำสั่งที่เกี่ยวข้อง
                  </p>
                  <p>
                    2) เพื่อให้บริการและบริหารจัดการบริการของ กสอ.
                    ทั้งบริการภายใต้สัญญาที่มีต่อท่าน หรือตามพันธกิจของ กสอ.
                  </p>
                  <p>3) เพื่อการดำเนินการทางธุรกรรมของ กสอ.</p>
                  <p>
                    4) ควบคุมดูแล ใช้งาน ติดตาม
                    ตรวจสอบและบริหารจัดการบริการเพื่ออำนวยความสะดวกและสอดคล้องกับความต้องการของท่าน
                  </p>
                  <p>
                    5) เพื่อเก็บรักษาและปรับปรุงข้อมูลอันเกี่ยวกับท่าน
                    รวมทั้งเอกสารที่มีการกล่าวอ้างถึงท่าน
                  </p>
                  <p>
                    6)
                    จัดทำบันทึกรายการการประมวลผลข้อมูลส่วนบุคคลตามที่กฎหมายกำหนด
                  </p>
                  <p>
                    7) วิเคราะห์ข้อมูล รวมถึงแก้ไขปัญหาที่เกี่ยวกับบริการของ
                    กสอ.
                  </p>
                  <p>
                    8) เพื่อดำเนินการตามที่จำเป็นในการบริหารจัดการภายในองค์กร
                    รวมถึงการรับสมัครงาน การสรรหากรรมการหรือผู้ดำรงตำแหน่งต่าง ๆ
                    การประเมินคุณสมบัติ
                  </p>
                  <p>
                    9) ป้องกัน ตรวจจับ หลีกเลี่ยง และตรวจสอบการฉ้อโกง
                    การละเมิดความปลอดภัย หรือการกระทำที่ต้องห้าม หรือผิดกฎหมาย
                    และอาจเกิดความเสียหายต่อทั้ง กสอ. และเจ้าของข้อมูลส่วนบุคคล
                  </p>
                  <p>
                    10) การยืนยันตัวตน
                    พิสูจน์ตัวตนและตรวจสอบข้อมูลเมื่อท่านสมัครใช้บริการของ กสอ.
                    หรือติดต่อใช้บริการ หรือใช้สิทธิตามกฎหมาย
                  </p>
                  <p>11) ปรับปรุงและพัฒนาคุณภาพผลิตภัณฑ์และบริการให้ทันสมัย</p>
                  <p>12) การประเมินและบริหารจัดการความเสี่ยง</p>

                  <p>
                    13) ส่งการแจ้งเตือน การยืนยันการทำคำสั่ง
                    ติดต่อสื่อสารและแจ้งข่าวสารไปยังท่าน
                  </p>
                  <p>
                    14)
                    เพื่อจัดทำและส่งมอบเอกสารหรือข้อมูลที่มีความเกี่ยวข้องและจำเป็น
                  </p>
                  <p>
                    15) ยืนยันตัวตน ป้องกันการสแปม
                    หรือการกระทำที่ไม่ได้รับอนุญาต หรือผิดกฎหมาย
                  </p>
                  <p>
                    16) ตรวจสอบว่าเจ้าของข้อมูลส่วนบุคคลเข้าถึงและใช้บริการของ
                    กสอ. อย่างไร ทั้งในภาพรวมและรายบุคคล
                    และเพื่อวัตถุประสงค์ที่เกี่ยวกับการค้นคว้า และการวิเคราะห์
                  </p>
                  <p>
                    17) ดำเนินการตามที่จำเป็นเพื่อปฏิบัติตามหน้าที่ที่ กสอ.
                    มีต่อหน่วยงานที่มีอำนาจควบคุม หน่วยงานด้านภาษี
                    การบังคับใช้กฎหมาย หรือภาระผูกพันตามกฎหมายของ กสอ.
                  </p>
                  <p>
                    18) ดำเนินการตามที่จำเป็นเพื่อประโยชน์ที่ชอบด้วยกฎหมายของ
                    กสอ. หรือของบุคคลอื่น
                    หรือของนิติบุคคลอื่นที่เกี่ยวข้องกับการการดำเนินการของ กสอ.
                  </p>
                  <p>
                    19) ป้องกัน หรือหยุดยั้งอันตรายต่อชีวิต ร่างกาย
                    หรือสุขภาพของบุคคลซึ่งรวมถึงการเฝ้าระวังโรคระบาด
                  </p>
                  <p>
                    20) จัดเตรียมเอกสารทางประวัติศาสตร์เพื่อประโยชน์สาธารณะ
                    การค้นคว้า หรือจัดทำสถิติที่ กสอ. ได้รับมอบหมายให้ดำเนินการ
                  </p>
                  <p>
                    21) เพื่อการปฏิบัติตามกฎหมาย ประกาศ คำสั่งที่มีผลบังคับใช้
                    หรือการดำเนินการเกี่ยวกับ คดีความ
                    การดำเนินการเกี่ยวกับข้อมูลตามหมายศาล
                    รวมถึงการใช้สิทธิเกี่ยวกับข้อมูลของท่าน
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-3">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-3"
                  aria-expanded="false"
                >
                  แหล่งที่มาของข้อมูลส่วนบุคคลที่ กสอ. เก็บรวบรวม
                </button>
              </h5>
              <div
                id="collapse-3"
                class="accordion-collapse collapse"
                aria-labelledby="faq-3"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    กสอ. เก็บรวบรวมหรือได้มาซึ่งข้อมูลส่วนบุคคลประเภทต่าง ๆ
                    จากแหล่งข้อมูลดังต่อไปนี้
                  </p>
                  <p>
                    1) ข้อมูลส่วนบุคคลที่ กสอ.
                    เก็บรวบรวมจากเจ้าของข้อมูลส่วนบุคคลโดยตรงในช่องทางให้บริการต่าง
                    ๆ เช่น ขั้นตอนการสมัคร ลงทะเบียน สมัครงาน ลงนามในสัญญา
                    เอกสาร ทำแบบสำรวจหรือใช้งานผลิตภัณฑ์ บริการ
                    หรือช่องทางให้บริการอื่นที่ควบคุมดูแลโดย กสอ.
                    หรือเมื่อเจ้าของข้อมูลส่วนบุคคลติดต่อสื่อสารกับ กสอ. ณ
                    ที่ทำการหรือผ่านช่องทางติดต่ออื่นที่ควบคุมดูแลโดย กสอ.
                    เป็นต้น
                  </p>
                  <p>
                    2) ข้อมูลที่ กสอ.
                    เก็บรวบรวมจากการที่เจ้าของข้อมูลส่วนบุคคลเข้าใช้งานเว็บไซต์
                    ผลิตภัณฑ์หรือบริการอื่น ๆ ตามสัญญาหรือตามพันธกิจ เช่น
                    การติดตามพฤติกรรมการใช้งานเว็บไซต์ ผลิตภัณฑ์หรือบริการของ
                    กสอ. ด้วยการใช้คุกกี้ (Cookies) ข้อมูลจราจร (Data Traffic)
                    หรือจากซอฟต์แวร์บนอุปกรณ์ของเจ้าของข้อมูลส่วนบุคคล เป็นต้น
                  </p>
                  <p>
                    3) ข้อมูลส่วนบุคคลที่ กสอ.
                    เก็บรวบรวมจากแหล่งอื่นนอกจากเจ้าของข้อมูลส่วนบุคคล
                    โดยที่แหล่งข้อมูลดังกล่าวมีอำนาจหน้าที่
                    มีเหตุผลที่ชอบด้วยกฎหมายหรือได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคลแล้วในการเปิดเผยข้อมูลแก่
                    กสอ. เช่น
                    การเชื่อมโยงบริการดิจิทัลของหน่วยงานของรัฐในการให้บริการเพื่อประโยชน์สาธารณะแบบเบ็ดเสร็จแก่เจ้าของข้อมูลส่วนบุคคลเอง
                    การรับข้อมูลส่วนบุคคลจากหน่วยงานของรัฐแห่งอื่นในฐานะที่ กสอ.
                    มีหน้าที่ตามพันธกิจ
                    ในการดำเนินการจัดให้มีศูนย์แลกเปลี่ยนข้อมูลกลางเพื่อสนับสนุนการดำเนินการของหน่วยงาน
                    ของรัฐในการให้บริการประชาชนผ่านระบบดิจิทัล
                    รวมถึงจากความจำเป็นเพื่อให้บริการตามสัญญา
                    ที่อาจมีการแลกเปลี่ยนข้อมูลส่วนบุคคลกับหน่วยงานคู่สัญญาได้
                  </p>
                  <p>
                    นอกจากนี้
                    ยังหมายความรวมถึงกรณีที่ท่านเป็นผู้ให้ข้อมูลส่วนบุคคลของบุคคลภายนอก
                    แก่ กสอ. ดังนี้
                    ท่านมีหน้าที่รับผิดชอบในการแจ้งรายละเอียดตามนโยบายนี้หรือประกาศของผลิตภัณฑ์หรือบริการ
                    ตามแต่กรณี ให้บุคคลดังกล่าวทราบ
                    ตลอดจนขอความยินยอมจากบุคคลนั้นหากเป็นกรณีที่ต้องได้รับความยินยอมในการเปิดเผยข้อมูลแก่
                    กสอ.
                  </p>

                  <p>
                    ทั้งนี้
                    ในกรณีที่เจ้าของข้อมูลส่วนบุคคลปฏิเสธไม่ให้ข้อมูลที่มีความจำเป็นในการให้บริการของ
                    กสอ. อาจเป็นผลให้ กสอ.
                    ไม่สามารถให้บริการนั้นแก่เจ้าของข้อมูลส่วนบุคคลดังกล่าวได้ทั้งหมดหรือบางส่วน
                  </p>
                </div>
              </div>
            </div>

            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-9">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-4"
                  aria-expanded="false"
                >
                  ระยะเวลาในการเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน
                </button>
              </h5>
              <div
                id="collapse-4"
                class="accordion-collapse collapse"
                aria-labelledby="faq-9"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  กสอ.
                  จะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้ในระยะเวลาเท่าที่ข้อมูลนั้นยังมีความจำเป็นตามวัตถุประสงค์ในการเก็บรวบรวมข้อมูลเท่านั้น
                  ตามรายละเอียดที่ได้กำหนดไว้ในนโยบาย ประกาศหรือ
                  ตามกฎหมายที่เกี่ยวข้อง ทั้งนี้
                  เมื่อพ้นระยะเวลาและข้อมูลส่วนบุคคลของท่านสิ้นความจำเป็นตามวัตถุประสงค์ดังกล่าวแล้ว
                  กสอ. จะทำการลบ ทำลายข้อมูลส่วนบุคคลของท่าน
                  หรือทำให้ข้อมูลส่วนบุคคลของท่าน ไม่สามารถระบุตัวตนได้ต่อไป
                  ตามรูปแบบและมาตรฐานการลบทำลายข้อมูลส่วนบุคคลที่คณะกรรมการหรือกฎหมายจะได้ประกาศกำหนดหรือตามมาตรฐานสากล
                  อย่างไรก็ดี ในกรณีที่มีข้อพิพาท การใช้สิทธิหรือ
                  คดีความอันเกี่ยวข้องกับข้อมูลส่วนบุคคลของท่าน กสอ.
                  ขอสงวนสิทธิในการเก็บรักษาข้อมูลนั้นต่อไปจนกว่าข้อพิพาทนั้นจะได้มีคำสั่งหรือคำพิพากษาถึงที่สุด
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-10">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-5"
                  aria-expanded="false"
                >
                  การปรับปรุงแก้ไขนโยบายการคุ้มครองข้อมูลส่วนบุคคล
                </button>
              </h5>
              <div
                id="collapse-5"
                class="accordion-collapse collapse"
                aria-labelledby="faq-10"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  กสอ. อาจพิจารณาปรับปรุง
                  แก้ไขหรือเปลี่ยนแปลงนโยบายนี้ตามที่เห็นสมควร
                  และจะทำการแจ้งให้ท่านทราบผ่านช่องทางเว็บไซต์ www.dip.go.th
                  โดยมีวันที่มีผลบังคับใช้ของแต่ละฉบับแก้ไขกำกับอยู่ อย่างไรก็ดี
                  กสอ.
                  ขอแนะนำให้ท่านโปรดตรวจสอบเพื่อรับทราบนโยบายฉบับใหม่อย่างสม่ำเสมอ
                  ผ่านแอปพลิเคชัน หรือช่องทางเฉพาะกิจกรรมที่ กสอ ดำเนินการ
                  โดยเฉพาะก่อนที่ท่านจะทำการเปิดเผยข้อมูลส่วนบุคคลแก่ กสอ.
                  การเข้าใช้งานผลิตภัณฑ์หรือบริการของ กสอ.
                  ภายหลังการบังคับใช้นโยบายใหม่
                  ถือเป็นการรับทราบตามข้อตกลงในนโยบายใหม่แล้ว ทั้งนี้
                  โปรดหยุดการเข้าใช้งานหากท่านไม่เห็นด้วยกับรายละเอียดในนโยบายฉบับนี้และโปรดติดต่อมายัง
                  กสอ. เพื่อชี้แจงข้อเท็จจริงต่อไป
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-11">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-6"
                  aria-expanded="true"
                >
                  การติดต่อสอบถามหรือใช้สิทธิ
                </button>
              </h5>
              <div
                id="collapse-6"
                class="accordion-collapse collapse show"
                aria-labelledby="faq-11"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    หากท่านมีข้อสงสัย
                    ข้อเสนอแนะหรือข้อกังวลเกี่ยวกับการเก็บรวบรวม ใช้
                    และเปิดเผยข้อมูลส่วนบุคคลของ กสอ. หรือเกี่ยวกับนโยบายนี้
                    หรือท่านต้องการใช้สิทธิตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
                    ท่านสามารถติดต่อสอบถามได้ที่
                  </p>
                  <p>ผู้ควบคุมข้อมูลส่วนบุคคล (Data Controller)</p>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;(1) ชื่อ: กรมส่งเสริมอุตสาหกรรม</p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;(2) สถานที่ติดต่อ : เลขที่ 75/6
                    ถนนพระรามที่ 6 ราชเทวี กรุงเทพฯ 10400
                  </p>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;(3) ช่องทางการติดต่อ :</p>
                  <!--<p>
                                                โทรศัพท์ : 0 2430 6967
                                            </p>
                                            <p>
                                                จดหมายอิเล็กทรอนิกส์ : service_ids@industry.go.th
                                            </p>
                                            <p>
                                                Line : @iindustry
                                            </p>
                                            <p>
                                                Facebook : iindustrymoi
                                            </p>-->
                  <div class="pricing-info mb-4">
                    <ul class="pricing-feature-list list-unstyled">
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;โทรศัพท์ : 0 2430 6860
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;จดหมายอิเล็กทรอนิกส์ :webmaster@dip.go.th
                      </li>
                      <!--<li>&nbsp;&nbsp; &nbsp;&nbsp;<i class="fas fa-circle fa-2xs text-primary me-2"></i>  &nbsp;&nbsp;Line : @iindustry</li>
                                                    <li>&nbsp;&nbsp; &nbsp;&nbsp;<i class="fas fa-circle fa-2xs text-primary me-2"></i>  &nbsp;&nbsp; Facebook : iindustrymoi</li>-->
                    </ul>
                  </div>
                  <p>
                    เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer:
                    DPO)
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;(1) ชื่อ : กรมส่งเสริมอุตสาหกรรม
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;(2) สถานที่ติดต่อ :
                    กรมส่งเสริมอุตสาหกรรม เลขที่ 75/6 ถนนพระรามที่ 6 ราชเทวี
                    กรุงเทพฯ 10400
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;(3) ช่องทางการติดต่อ :
                    webmaster@dip.go.th
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="text-center mt-5">
          <a
            :href="`/Privacy_Policy_DIPROM.pdf`"
            class="btn btn-primary"
            rel="noopener noreferrer"
            target="_blank"
            >ดาวโหลดนโยบายการคุ้มครองข้อมูลส่วนบุคคล(Privacy Policy) ฉบับเต็ม
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionPrivacyPolicy",
  props: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  created() {},
  mounted() {
    console.log("publicPath", process.env.BASE_URL);
  },
  methods: {},
};
</script>

<style></style>
