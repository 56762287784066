<template>
  <section
    class="hero-section hero-five min-vh-100 d-flex align-items-center bg-dark text-white position-relative overflow-hidden"
    style="min-height: 60vh !important"
  >
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-lg-7">
          <div class="hero-content-wrap">
            <h5 class="text-warning">DIPROM E-Services</h5>
            <h1 class="fw-bold display-5">บริการดีพร้อม</h1>
            <p class="lead">
              การให้บริการแก่ประชาชนและผู้ประกอบการในรูปแบบอิเล็กทรอนิกส์ของ<br />
              กรมส่งเสริมอุตสาหกรรม ซึ่งครอบคลุมทั้งด้านการให้คำปรึกษาแนะนำ
              การฝึกอบรม<br>ความช่วยเหลือด้านการเงิน
              และบริการด้านข้อมูลที่เป็นประโยชน์แก่ SMEs
            </p>

            <div class="action-btns mt-5">
              <a v-on:click="CallSwal()" class="btn btn-primary me-3"
                >สมัครสมาชิก</a
              >
              <a
                :href="customerUrl"
                class="btn btn-outline-light"
                >เข้าสู่ระบบ</a
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 mt-5 mt-lg-0 animated-custom cus-padding">
          <div class="animated-img-wrap animated-img-wrap-custom">
            <!--animated shape start-->
            <ul
              class="animate-element parallax-element animated-hero-1 animated-left-custom"
            >
              <li class="layer" data-depth="0.02">
                <img
                  src="@/assets/img/screen/animated-screen-2.svg"
                  alt="shape"
                  class="img-fluid position-absolute type-0"
                  style="width: 300px"
                />
              </li>
            </ul>
            <!--animated shape end-->
            <img
              src="@/assets/img/screen/animated-screen-1.svg"
              alt="shape"
              class="position-relative img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style>
.animated-custom :hover ul {
  transform: translateX(-30%);
}
.animated-left-custom {
  position: relative;
  transition: transform 1.5s ease;
  transform: translateX(0px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.input-container {
  display: flex;
  width: 100%;
  border: 1px solid #a9a9a9;
  justify-content: space-between;
  background-color: white;
  border-radius: 30px;
}
.input-container input:focus,
.input-container input:active {
  outline: none;
}
.input-container input {
  border: none;
}
.input-container button {
  width: 120px;
  height: fit-content;
  line-height: 1;
  margin: auto;
  margin-right: 5px;
}
.radius-30 {
  border-radius: 30px !important;
}
.display-7 {
  font-size: calc(1.075rem + 1vw) !important;
  font-weight: 300 !important;
  line-height: 1.2 !important;
}
.cus-padding {
  padding-top: 200px !important;
}
@media (min-width: 1200px) {
  .display-7 {
    font-size: 2rem !important;
  }
}
@media (max-width: 992px) {
  .cus-padding {
    padding-top: 0px !important;
  }
}

/* ปรับขนาด Title ของ Sweet Alert ของหน้าสมัคร */
h2#swal2-title
{
   font-size: 16px;
}

</style>
<script>
import store from "../../store";
import SearchExample from "../common/SearchExample.vue";
export default {
  components: {
    SearchExample,
  },
  data() {
    return {
      textSearch: null,
      customerUrl: store.getters.CustomerUrl,
    };
  },
  mounted() {
    this.SetDefault();
  },
  methods: {
    SetDefault() {
      this.textSearch = null;
    },
    OnSearch() {
      //alert(this.textSearch)
      store.commit("SetNewsDetail", 0);
      this.$router.push({
        name: "News",
        params: { textSearch: this.textSearch },
      });
    },
  },
};
</script>
