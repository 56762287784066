<template>
  <section id="ServiceSction" class="feature-promo ptb-60">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-10">
          <div class="section-heading text-center">
            <h2>บริการดีพร้อม</h2>
            <p>
              ระบบงานที่เปิดให้บริการภายใต้ระบบบริการดีพร้อม (DIPROM E-Services)
            </p>
          </div>
        </div>
      </div>
      <div class="row pt-lg-5 pt-3">
        <div class="col-lg-6 mt-4">
          <div
            class="position-relative d-flex flex-column h-100 flex-wrap bg-danger-soft p-5 rounded-custom"
          >
            <div class="cta-left-info mb-2">
              <h5>ข่าวสาร และกิจกรรม</h5>
              <p>
                ข้อมูลประชาสัมพันธ์ข่าวสารกิจกรรมที่สำคัญที่เกี่ยวข้องกับการส่งเสริมและสนับสนุนธุรกิจให้ประชาชนทราบ
              </p>
            </div>
            <div class="mt-auto">
              <a v-on:click="OnClickService" class="btn btn-outline-primary btn-sm"
                >เข้าร่วมบริการ</a
              >
            </div>
            <div class="cta-img position-absolute right-0 bottom-0">
              <img
                src="@/assets/img/cta-news.png"
                alt="cta img"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-4">
          <div
            class="position-relative d-flex flex-column h-100 flex-wrap bg-primary-soft p-5 rounded-custom"
          >
            <div class="cta-left-info mb-2">
              <h5>ระบบคลังความรู้</h5>
              <p>
                แหล่งรวมความรู้ด้านการประกอบการและการพัฒนาธุรกิจที่เผยแพร่ให้ทุกท่านสามารถเข้ามาค้นคว้า
                และศึกษาเรียนรู้ได้ด้วยตนเองตลอด 24 ชั่วโมง
              </p>
            </div>
            <div class="mt-auto">
              <a v-on:click="OnClickService" class="btn btn-outline-primary btn-sm"
                >เข้าร่วมบริการ</a
              >
            </div>
            <div class="cta-img position-absolute right-0 bottom-0">
              <img src="@/assets/img/faq.svg" alt="cta img" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-lg-5 pt-3">
        <div class="col-lg-6 mt-4">
          <div
            class="position-relative d-flex flex-column h-100 flex-wrap bg-danger-soft p-5 rounded-custom"
          >
            <div class="cta-left-info mb-2">
              <h5>ฝึกอบรมออนไลน์</h5>
              <p>
                เปรียบเสมือนห้องเรียนดิจิทัลที่ให้ท่านที่สนใจเรียนรู้
                ผ่านบทเรียนต่างๆ ในรูปแบบออนไลน์ ทั้งเรียนสดตรงกับวิทยากร หรือ
                เรียนผ่านระบบเรียนรู้อิเล็กทรอนิกส์
              </p>
            </div>
            <div class="mt-auto">
              <a v-on:click="OnClickService" class="btn btn-outline-primary btn-sm"
                >เข้าร่วมบริการ</a
              >
            </div>
            <div class="cta-img position-absolute right-0 bottom-0">
              <img
                src="@/assets/img/cta-training.png"
                alt="cta img"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-4">
          <div
            class="position-relative d-flex flex-column h-100 flex-wrap bg-primary-soft p-5 rounded-custom"
          >
            <div class="cta-left-info mb-2">
              <h5>ปรึกษาแนะนำออนไลน์</h5>
              <p>
                เป็นแหล่งรวมที่ปรึกษาด้านธุรกิจและอุตสาหกรรม
                โดยสามารถค้นหาและนัดหมายกับผู้เชี่ยวชาญได้โดยตรง
                เพื่อให้ท่านมีกูรูช่วยเหลือในการดำเนินธุรกิจ
              </p>
            </div>
            <div class="mt-auto">
              <a v-on:click="OnClickService" class="btn btn-outline-primary btn-sm"
                >เข้าร่วมบริการ</a
              >
            </div>
            <div class="cta-img position-absolute right-0 bottom-0">
              <img
                src="@/assets/img/feature-hero-img-2.svg"
                alt="cta img"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-lg-5 pt-3">
        <div class="col-lg-6 mt-4">
          <div
            class="position-relative d-flex flex-column h-100 flex-wrap bg-danger-soft p-5 rounded-custom"
          >
            <div class="cta-left-info mb-2">
              <h5>เงินทุนหมุนเวียน</h5>
              <p>
                บริการเชื่อมโยงธุรกิจกับสินเชื่อเงินทุนหมุนเวียนของกรมส่งเสริมอุตสาหกรรม
                โดยท่านสามารถสมัครและส่งเอกสารต่าง ๆ
                เพื่อเข้ารับการพิจารณาสินเชื่อจากช่องทางออนไลน์นี้
              </p>
            </div>
            <div class="mt-auto">
              <a v-on:click="OnClickService" class="btn btn-outline-primary btn-sm"
                >เข้าร่วมบริการ</a
              >
            </div>
            <div class="cta-img position-absolute right-0 bottom-0">
              <img
                src="@/assets/img/coin.png"
                alt="cta img"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-4">
          <div
            class="position-relative d-flex flex-column h-100 flex-wrap bg-primary-soft p-5 rounded-custom"
          >
            <div class="cta-left-info mb-2">
              <h5>บริการข้อมูลและเชื่อมโยงธุรกิจ</h5>
              <p>
                แหล่งรวบรวมข้อมูลธุรกิจที่ต่างๆ ทั้งเป็นผู้ผลิต ผู้ค้า
                ตัวแทนจำหน่าย ให้ท่านสามารถสืบค้นข้อมูล
                และหากสนใจที่จะมาเป็นส่วนหนึ่งกับเรา ก็สามารถให้ข้อมูลธุรกิจท่าน
                มาให้เราลงข้อมูลได้เพื่อเผยแพร่
              </p>
            </div>
            <div class="mt-auto">
              <a v-on:click="OnClickService" class="btn btn-outline-primary btn-sm"
                >เข้าร่วมบริการ</a
              >
            </div>
            <div class="cta-img position-absolute right-0 bottom-0">
              <img
                src="@/assets/img/screen/animated-screen-1.svg"
                alt="cta img"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-lg-5 pt-3">
        <div class="col-lg-6 mt-4">
          <div
            class="position-relative d-flex flex-column h-100 flex-wrap bg-danger-soft p-5 rounded-custom"
          >
            <div class="cta-left-info mb-2">
              <h5>ตลาดออนไลน์</h5>
              <p>
                แหล่งซื้อขายออนไลน์ ที่ท่านสามารถนำสินค้าของท่านมานำเสนอขายได้
                ซึ่งกลุ่มคนที่มาร่วมขายสินค้าออนไลน์จะเป็นกลุ่มลูกค้าที่ได้รับการพัฒนาจาก<br>กรมส่งเสริมอุตสาหกรรมอย่างต่อเนื่อง
              </p>
            </div>
            <div class="mt-auto">
              <a v-on:click="OnClickService" class="btn btn-outline-primary btn-sm"
                >เข้าร่วมบริการ</a
              >
            </div>
            <div class="cta-img position-absolute right-0 bottom-0">
              <img
                src="@/assets/img/cr-card.png"
                alt="cta img"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-4">
          <div
            class="position-relative d-flex flex-column h-100 flex-wrap bg-primary-soft p-5 rounded-custom"
          >
            <div class="cta-left-info mb-2">
              <h5>รับสมัครเข้าร่วมโครงการ</h5>
              <p>
                ช่องทางให้ท่านเข้าถึงโครงการและกิจกรรมของ<br>กรมส่งเสริมอุตสาหกรรม
                โดยผู้ที่สนใจสามารถเข้ามาค้นหาโครงการภายใต้งบประมาณประจำปีของ<br>กรมส่งเสริมอุตสาหกรรม
              </p>
            </div>
            <div class="mt-auto">
              <a v-on:click="OnClickService" class="btn btn-outline-primary btn-sm"
                >เข้าร่วมบริการ</a
              >
            </div>
            <div class="cta-img position-absolute right-0 bottom-0">
              <img
                src="@/assets/img/business-and-trade-01.png"
                alt="cta img"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import store from "../../store";
export default {
  components: {},
  data() {
    return {
      customerUrl: store.getters.CustomerUrl,
    };
  },
  mounted() {},
  methods: {
    OnClickService(){
      window.open(this.customerUrl, "_blank");
    },
  },
};
</script>
