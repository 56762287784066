<template>
  <section class="our-integration ptb-120 bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12">
          <div class="section-heading" data-aos="fade-up">
            <h2>ประเภทข่าว</h2>
            <p>
            </p>
          </div>
        </div>
      </div>
      <div class="position-relative w-100">
        <div class="row">
          <div class="col-lg-3 col-md-6" v-for="(item, index) in SubCategoryItem" :key="index">
            <div v-on:click="GoToViewAllNews(item.Id)"
              class="cursor-cus position-relative text-decoration-none connected-app-single bg-white border border-2 transition-base rounded-custom d-block overflow-hidden p-4"
              :class="index == 0 ?
                        'mt-0 mt-lg-0 mt-md-0' :
                      index == 1  ?
                        'mt-4 mt-lg-0 mt-md-0 ' :
                      index >1  &&  index < 4?
                        'mt-4 mt-lg-0 ':
                        'mt-4 '"
            >
              <div class="text-center position-relative connected-app-content">
                <div class="p-4 d-inline-block">
                  <img
                    :src="item.IconPath"
                    width="60"
                    alt="integration"
                    class="img-fluid height-custom"
                  />
                </div>
                
                <h5></h5>
                <p class="mb-0 text-muted">
                  {{item.Name}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
</template>

<style>
.padding-div {
  margin-bottom: 20px;
}
.cursor-cus{
  cursor: pointer;
}
.bg-blue-light{
  background-color: #0c60ff;
}
.height-custom{
  height: 65px !important;
}
</style>

<script>
import store from "../../store";

export default {
  name: 'SubCate',
  data(){
    return{
      SubCategoryItem: [],
    }
  },
  mounted(){
    this.SetDefault();
    this.LoadSubCateById();

  },
  methods: {
    SetDefault(){
      this.SubCategoryItem = [];
    },
    GenImgSrc(path){
      return require(`@/assets/img/icons/category/subCategory/${path}`)
    },
    LoadSubCateById(){
      const typeId = store.getters.NewsDetail.Type
      const CateId = store.getters.NewsDetail.CateId
      const cateData = store.getters.CategoryData
      this.SubCategoryItem = (cateData.filter(e => e.RelationWebId == typeId)).filter(i => i.Id == CateId)[0].Childs

      // console.log('SubCategoryItem', this.SubCategoryItem);
      // console.log('cateData', cateData);
      // console.log('cateItem', this.CategoryItem);
    },
    GoToViewAllNews(id){
      store.commit('SetNewSubCateId', id);
      this.$router.push({ name: 'News' });
    },
  }
};
</script>
