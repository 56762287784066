import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueAwesomeSwiper from "vue-awesome-swiper";
import CoolLightBox from "vue-cool-lightbox";
import VueI18n from 'vue-i18n'

//Bootstrap CSS,JS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

//CSS
import "./assets/css/style.css";
import "./assets/css/custom-style.css";
import "./assets/css/fontawesome-all.min.css";
import "./assets/css/swiper-bundle.min.css";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import store from "./store";
import Swal from "sweetalert2";


Vue.use(VueAwesomeSwiper);
Vue.use(CoolLightBox);
Vue.use(VueI18n);


Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    GolbalGoToViewAllNews(typeId){

      store.commit('SetNewsDetail', typeId);
      let pathName = null;
      let temptypeId = null;
      temptypeId = typeId;
      
      if(temptypeId == 2) {
        pathName = 'Category';
      } else {
        pathName = 'News';
      }

      this.$router.push({name: pathName , query: {
        nti: temptypeId }});
      //console.log("this.$route", this.$route);
      // console.log("store", store.getters.NewsDetail);
    },
    OnChangeLanguage(value) {
      //console.log("lang", this.$i18n.locale)
      this.$i18n.locale = value
    },
    checkCurrentUrl(idName) {
      let currentUrl = window.location.pathname;
      if(currentUrl != "/") {
        this.$router.push({ name: "HomeThree" , params: {id: idName}});
      }
      //console.log("currentUrl", currentUrl);
    },
    CallSwal(){
      // let textM = null;
      // if($i18n.locale == "th") {
      //   textM = "คุณมีเลขทะเบียนโรงงานหรือไม่";
      // } else {
      //   textM = "Do you have factory registration Number?";
      // }
      let url = "";
      Swal.fire({
        title: "คุณต้องการสมัครเป็นสมาชิกลูกค้ากระทรวงอุตสาหกรรม (i-Industry) หรือไม่",
        icon: "question",
        reverseButtons: true,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "สมัครสมาชิก",
        denyButtonText: "ไม่สมัครสมาชิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          url = "https://i.industry.go.th/register/";
          window.open(url, "_blank");
        }
      })
    },
  },
})

// Ready translated locale messages
const messages = {
  en: {
    message: {
      hello: 'hello world',
      homepage: "Homepage",
      service: "E-Services",
      userManual: "Instruction",
      joinWithUs: "Join DIPROM Alliance",
      activity: "Activity",
      knowledge: "Knowledge",
      about: "About Us",
      register: "Register",
      login: "Login",
      contactUs: "Contact Us",
      companyName: "Department of industrial Promotion",
      address: "75/6 Rama 6 Rd, Ratchathewi, Bangkok 10400",
      tel: "Tel.",
      fax: "Fax.",
      changeLanguage:"ไทย/ENG",
      changeLangText:"กรุณาเลือกภาษา",
      copyRight: "© 2022, Department of industrial Promotion. All rights reserved.",
      pdpa: "Privacy Notice",
      privacyPolicy: "Pivacy Policy",
      rating: "Overall rating",
    },
    lang: {
      thai:"ไทย", 
      english:"อังกฤษ", 
    },
    system: {
      activityService: "News & Activities",
      knowledgeService: "Knowledge",
      cousultingService: "E-Consulting",
      matchingService: "Business Matching",
      fundingService: "Working Capital",
      onlineMarket: "E-Marketplace",
      trainingService: "Online Training",
      projectService:"DIRPOM Projects",
    }
  },
  th: {
    message: {
      hello: 'สวัสดี',
      homepage: "หน้าหลัก",
      service: "บริการดีพร้อม",
      userManual: "แนะนำการใช้งาน",
      joinWithUs: "ร่วมเป็นส่วนหนึ่งกับเรา",
      activity: "ข่าวสารและกิจกรรม",
      knowledge: "คลังความรู้",
      about: "รู้จักดีพร้อม",
      register: "สมัครสมาชิก",
      login: "เข้าสู่ระบบ",
      contactUs: "ติดต่อเรา",
      companyName: "กรมส่งเสริมอุตสาหกรรม",
      address: "75/6 ถนนพระรามที่ 6 ราชเทวี กรุงเทพฯ 10900",
      tel: "โทร",
      fax: "แฟกซ์",
      changeLanguage:"ไทย/ENG",
      changeLangText:"Select language",
      copyRight: "สงวนลิขสิทธิ์ © 2022 กรมส่งเสริมอุตสาหกรรม กระทรวงอุตสาหกรรม",
      pdpa: "นโยบายความเป็นส่วนตัว (Privacy Notice)",
      privacyPolicy: "นโยบายการคุ้มครองข้อมูลส่วนบุคคล(Privacy Policy)",
      rating: "ความพึงพอใจในภาพรวม",
    },
    lang: {
      thai:"Thai", 
      english:"English", 
    },
    system: {
      activityService: "ข่าวสารและกิจกรรม",
      knowledgeService: "คลังความรู้",
      cousultingService: "ปรึกษาแนะนำออนไลน์",
      matchingService: "จับคู่ธุรกิจ",
      fundingService: "เงินทุนหมุนเวียน",
      onlineMarket: "ตลาดสินค้าอออนไลน์",
      trainingService: "ฝึกอบรมออนไลน์",
      projectService: "โครงการและกิจกรรม",
    }
  }
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'th', // set locale
  messages, // set locale messages
})
//Vue.use(i18n);

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
