<template>
  <div class="main-wrapper">
    <Navbar />
    <NewsHeader/>
    <SubCategoryItems/>
    <Footer/>
  </div>
</template>

<script>
import Footer from "../../../components/footer/Footer.vue";
import Navbar from "../../../components/nav/Navbar.vue";
import NewsHeader from "../../../components/news/NewsHeader.vue";
import SubCategoryItems from "../../../components/category/SubCategoryItems.vue";

export default {
  components: { Navbar,
                NewsHeader, 
                SubCategoryItems,
                Footer 
  },
};
</script>