<template>
    <p class="font-large" style="padding-left: 10px;" >ตัวอย่างคำค้นหา  
        <span class="badge integration-badge bg-info-soft px-2 py-1 text-secondary" style="margin-right: 5px;">ธุรกิจ</span> 
        <span class="badge integration-badge bg-info-soft px-2 py-1 text-secondary" style="margin-right: 5px;">กิจการ</span>  
        <span class="badge integration-badge bg-info-soft px-2 py-1 text-secondary" style="margin-right: 5px;">เริ่มธุรกิจ</span> 
        <span class="badge integration-badge bg-info-soft px-2 py-1 text-secondary" style="margin-right: 5px;">ตลาด</span> 
    </p>
</template>

<style>
.font-large {
  padding-left: 10px;
  padding-top: 10px;
  font-size: large;
}
.font-large span {
  font-size: large;
}
@media (max-width: 480px){
  .font-large {
    padding-top: 10px;
    font-size: .95rem;
  }
  .font-large span {
    font-size: .85rem;
  }
}
</style>

<script>
import store from "../../store";

export default {
};
</script>
