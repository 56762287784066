<template>
  <section
    class="home-blog-section ptb-120"
    :class="ClassName"
    style="padding: 80px 0 !important"
  >
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-5 col-md-12">
          <div class="section-heading" data-aos="fade-right">
            <h4 class="h3">{{ Title }}</h4>
            <p>{{ SubTitle }}</p>
          </div>
        </div>
        <div class="col-lg-7 col-md-12">
          <div class="text-lg-end mb-5 mb-lg-0" data-aos="fade-left">
            <button class="btn btn-primary" v-on:click="SetNewType(Id)">
              View All
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-6"
          v-for="(topNewsData, idx) in topNewsData"
          :key="idx"
        >
          <div
            class="single-article rounded-custom mb-4 mb-lg-0"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <a
              class="article-img cursor-custom"
              v-on:click="GoToDetail(topNewsData.Id)"
            >
              <img
                :src="
                  topNewsData.ImageFilesName.length > 0
                    ? topNewsData.ImageFilesName[0].Url
                    : ''
                "
                alt="article"
                class="img-fluid"
              />
            </a>
            <div class="article-content p-4">
              <div class="article-category mb-4 d-block">
                <i class="fa fa-eye" aria-hidden="true"></i>
                {{ topNewsData.TotalView }}
              </div>
              <a class="cursor-custom" v-on:click="GoToDetail(topNewsData.Id)">
                <h2 class="h5 article-title limit-1-line-text">
                  {{ topNewsData.Title }}
                </h2>
              </a>
              <p class="limit-1-line-text">
                {{ topNewsData.IntroText }}
              </p>
              <div class="d-flex align-items-center pt-4">
                <div class="avatar-info">
                  <span class="small fw-medium text-muted">
                    วันที่เผยแพร่ :
                    {{
                      topNewsData.PublicDate != null
                        ? moment(topNewsData.PublicDate, "DD-MM-YYYY").format(
                            "DD-MMM-YYYY"
                          )
                        : "-"
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import store from "../../store";
import moment from "moment";

const GetTopNewURI = `${store.getters.URL}/NewsAndActivity/get-top-news`;
const UpdateViewNewURI = `${store.getters.URL}/NewsAndActivity/update-view-news`;
export default {
  name: "LatestNews",
  props: {
    item: Object,
  },
  data() {
    return {
      Id: 0,
      Title: null,
      SubTitle: null,
      ClassName: null,
      getTopNewsVal: 3,
      topNewsData: [],
      moment,
    };
  },
  created() {},
  mounted() {
    // console.log('itemLatestNews', this.item)
    this.SetDefault();
    this.CheckProps();
    this.GetTopNews();
  },
  methods: {
    SetDefault() {
      this.Id = 0;
      this.Title = null;
      this.SubTitle = null;
      this.ClassName = null;
      this.topNewsData = [];
    },
    CheckProps() {
      this.Id = this.item.Id;
      this.Title = this.item.Title;
      this.SubTitle = this.item.SubTitle;
      this.ClassName = this.item.ClassName;
    },
    GetTopNews() {
      // console.log('id', this.Id);
      axios
        .post(GetTopNewURI, { SubWebsiteId: this.Id, SelectTop: 3 })
        .then((response) => {
          const responseData = response.data.Data;
          // console.log(responseData);
          this.topNewsData = responseData;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    SetNewType(id) {
      store.commit("SetNewsDetail", id);
      this.$router.push({ name: "News" });
    },
    GoToDetail(idNews) {
      store.commit("SetNewsId", idNews);

      axios
        .put(`${UpdateViewNewURI}?id=${idNews}`)
        .then((response) => {
          if (response.status == 200) {
            this.$router.push({ name: "NewsDetails" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
