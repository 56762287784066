<template>
  <div class="main-wrapper">
    <Navbar />
    <!-- <HeroThree /> -->
    <SectionOne />
    <SectionTwo />
    <SectionThree />
    <!-- <FeatureFour />
    <ImgContentTwo /> -->
    <!-- <TestimonialSlider /> -->
    <SectionNews
      :item="{
        Id: 1,
        Title: 'ข่าวสารและกิจกรรม',
        SubTitle:
          'ข้อมูลประชาสัมพันธ์ข่าวสารกิจกรรมที่สำคัญที่เกี่ยวข้องกับการส่งเสริมและสนับสนุนธุรกิจ',
      }"
    />
    <!-- <LatestNews :item="{Id: 1,Title: 'ข่าวสารและกิจกรรม', SubTitle: 'News & Activities', ClassName: 'bg-light'}"/> -->
    <!-- <LatestNews :item="{Id: 4,Title: 'ปรึกษาแนะนำออนไลน์', SubTitle: 'E-Consulting', ClassName: ''}"/>
    <LatestNews :item="{Id: 6,Title: 'จับคู่ธุรกิจ', SubTitle: 'Business Matching', ClassName: 'bg-light'}"/>
    <LatestNews :item="{Id: 7,Title: 'ตลาดสินค้าออนไลน์', SubTitle: 'E-Marketplace', ClassName: ''}"/> -->
    <Footer :footerDark="false" />
  </div>
</template>

<script>
import SectionOne from "../../components/MainComponents/SectionOne.vue";
import SectionTwo from "../../components/MainComponents/SectionTwo.vue";
import SectionThree from "../../components/MainComponents/SectionThree.vue";
import SectionNews from "../../components/MainComponents/SectionNews.vue";
// import FeatureFour from "../../components/features/FeatureFour.vue";
// import WorkProcess from "../../components/work-process/WorkProcess.vue";
// import LatestNews from "../../components/news/LatestNews.vue";
import Footer from "../../components/footer/Footer.vue";
//  import HeroThree from "../../components/hero/HeroThree.vue";
import Navbar from "../../components/nav/Navbar.vue";
// import TestimonialSlider from "../../components/testimonial/TestimonialSlider.vue";
// import ImgContentTwo from "../../components/img-content/ImgContentTwo.vue";
import axios from "axios";
import store from "../../store";

var GetSubWebsiteUrl = `${store.getters.URL}/NewsAndActivity/get-sub-website`;
var GetCategoryUrl = `${store.getters.URL}/NewsAndActivity/get-category-tree`;
export default {
  components: {
    SectionOne,
    SectionTwo,
    SectionThree,
    SectionNews,
    // HeroThree,
    // FeatureFour,
    // WorkProcess,
    // TestimonialSlider,
    Navbar,
    // LatestNews,
    // ImgContentTwo,
    Footer,
  },
  mounted() {
    this.GetSubWebsite();
    this.GetCategory();
    store.commit("SetNewsCateId", 0);
    store.commit("SetNewSubCateId", 0);
    //    console.log("this.$router.currentRoute :", this.$router.currentRoute);
    // console.log("hase :", this.$router.currentRoute.hash);
    var haseStr = this.$router.currentRoute.hash;
    if (haseStr && haseStr.includes("NewsDetailsPreviewOfficer/")) {
      //this.$router.push({ name: "NewsDetails" });

      var splitedStr = haseStr.split("#/NewsDetailsPreviewOfficer/"); // เพื่อต้องการเอา id ด้านหลัง

      console.log("splitedStr", splitedStr); //จะได้ exp. ['', '49']

      this.$router.push({
        name: "NewsDetailsPreview",
        params: { id: splitedStr[1] },
      });
    }
  },
  methods: {
    GetSubWebsite() {
      axios
        .get(GetSubWebsiteUrl)
        .then((response) => {
          const responseData = response.data.Data;
          //console.log(responseData);
          store.commit("SetSubWebSite", responseData);
          //console.log('subWeb', store.getters.SubWebSite)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetCategory() {
      axios
        .get(GetCategoryUrl)
        .then((response) => {
          const responseData = response.data.Data;
          //console.log(responseData);
          store.commit("SetCategoryData", responseData);
          //console.log('CategoryData', store.getters.CategoryData)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetCategoryTraining() {},
  },
};
</script>
