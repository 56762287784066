import Vue from "vue";
import VueRouter from "vue-router";
import HomeThree from "../views/pages/HomeThree.vue";
import News from "../views/pages/News.vue";
import NewsDetails from "../views/pages/NewsDetails.vue";
import Category from "../views/pages/Category/Category.vue";
import SubCategory from "../views/pages/Category/SubCategory.vue";
import AboutUs from "../views/pages/AboutUs.vue";
import PdpaPage from "../views/pages/PdpaPage.vue";
import NewsDetailsPreview from "../views/pages/NewsDetailsPreview.vue";
import PrivacyPolicyPage from "../views/pages/PrivacyPolicyPage.vue";
import CookiePolicy from "../views/pages/CookiePolicy";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: HomeThree,
    name: "HomeThree",
    meta: {
      title: "DIPROM",
    },
  },
  {
    path: "/news/:nti",
    name: "News",
    component: News,
    meta: {
      title: "News",
    },
    beforeEnter: (to, from, next) => {
      if (from.name == null) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/news-details/:id",
    name: "NewsDetails",
    component: NewsDetails,
    meta: {
      title: "News Details",
    },
    //props: true,
    // beforeEnter: (to, from, next) => {
    //   next("/");
    // },
  },
  {
    path: "/NewsDetailsPreviewOfficer/:id",
    name: "NewsDetailsPreview",
    component: NewsDetailsPreview,
    meta: {
      title: "News Details Preview",
    },
  },
  {
    path: "/category/:nti",
    name: "Category",
    component: Category,
    meta: {
      title: "Category",
    },
    beforeEnter: (to, from, next) => {
      if (from.name == null) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/sub-category/:nti",
    name: "SubCategory",
    component: SubCategory,
    meta: {
      title: "Sub Category",
    },
    beforeEnter: (to, from, next) => {
      if (from.name == null) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/AboutUs",
    name: "AboutUs",
    component: AboutUs,
    meta: {
      title: "About Us",
    },
  },
  {
    path: "/Pdpa",
    name: "Pdpa",
    component: PdpaPage,
    meta: {
      title: "Pdpa",
    },
  },
  {
    path: "/PrivacyPolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicyPage,
    meta: {
      title: "PrivacyPolicy",
    },
  },
  {
    path: "/CookiePolicy",
    name: "CookiePolicy",
    component: CookiePolicy,
    meta: {
      title: "Cookie Policy",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + " ";
  }
});

export default router;
