<template>
  <div
    class="offcanvas offcanvas-end d-xl-none"
    tabindex="-1"
    id="offcanvasWithBackdrop"
  >
    <div class="offcanvas-header d-flex align-items-center mt-4">
      <router-link
        to="/"
        class="d-flex align-items-center mb-md-0 text-decoration-none"
      >
        <img
          src="@/assets/img/logos/logo-footer.png"
          alt="logo"
          class="img-fluid ps-2"
          style="width: 113px"
        />
      </router-link>
      <button
        type="button"
        class="close-btn text-danger"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <i class="far fa-close"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <ul class="nav col-12 col-md-auto justify-content-center main-menu">
        <li>
          <router-link to="/" class="nav-link">{{
            $t("message.homepage")
          }}</router-link>
        </li>
        <li>
          <a
            href="#ServiceSction"
            @click="checkCurrentUrl('#adviseSection')"
            class="nav-link"
          >
            {{ $t("message.service") }}
          </a>
        </li>
        <li>
          <a
            href="#adviseSection"
            @click="checkCurrentUrl('#ServiceSction')"
            class="nav-link"
          >
            {{ $t("message.userManual") }}
          </a>
        <li>
          <router-link to="/AboutUs" class="nav-link">{{ $t("message.about") }}</router-link>
        </li>
        <li>
          <a href="#footer-section" class="nav-link">
            {{ $t("message.contactUs") }}
          </a>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ $t("message.changeLanguage") }}
          </a>
          <div
            class="dropdown-menu border-0 rounded-custom shadow py-0 bg-white"
          >
            <div class="dropdown-grid rounded-custom width-half">
              <div class="dropdown-grid-item">
                <h6 class="drop-heading">{{ $t("message.changeLangText") }}</h6>
                <a
                  v-on:click="OnChangeLanguage('th')"
                  class="dropdown-link cursor-pointer"
                >
                  <span
                    class="demo-list bg-th rounded text-white fw-bold"
                  ></span>
                  <div class="dropdown-info">
                    <div class="drop-title">{{ $t("lang.thai") }}</div>
                  </div>
                </a>
                <a
                  v-on:click="OnChangeLanguage('en')"
                  class="dropdown-link cursor-pointer"
                >
                  <span
                    class="demo-list bg-us rounded text-white fw-bold"
                  ></span>
                  <div class="dropdown-info">
                    <div class="drop-title">{{ $t("lang.english") }}</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </li>
        <li>
          <a
            class="nav-link"
            v-on:click="CallSwal()"
            target="_blank"
            style="cursor: pointer"
          >
            {{ $t("message.register") }}
          </a>
          <!-- <router-link to="https://dri.diprom.go.th" class="nav-link">สมัครสมาชิก</router-link> -->
        </li>
        <li>
          <a class="nav-link" :href="customerUrl" target="_blank">
            {{ $t("message.login") }}
          </a>
        </li>
        <!-- <li style="padding-left: 15px">
          <i class="fa-regular fa-globe"></i>
          <select
            class="form-select-sm"
            style="cursor: pointer; border: 0px; outline: none !important"
            v-model="$i18n.locale"
            @change="$i18n.locale = $i18n.locale"
          >
            <option value="th">ภาษาไทย</option>
            <option value="en">English</option>
          </select>
        </li> -->
      </ul>
      <!-- <div class="action-btns mt-4 ps-3">
        <router-link to="/login" class="btn btn-outline-primary me-2"
          >Sign In</router-link
        >
        <router-link to="/request-for-demo" class="btn btn-primary"
          >Get Started</router-link
        >
      </div> -->
    </div>
  </div>
</template>

<script>
import store from "../../store";
export default {
  name: "Offcanvas",
  data() {
    return {
      customerUrl: store.getters.CustomerUrl,
    };
  },
};
</script>
