<template>
  <section class="faq-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-12">
          <div class="section-heading mb-5 text-center">
            <img
              src="@/assets/img/logos/logo_dip_v.png"
              alt="logo"
              class="img-fluid logo-color"
              width="350"
            />
            <h5 class="h6 text-primary">บริการดีพร้อม (DIPROM E-Services)</h5>
            <h2>กรมส่งเสริมอุตสาหกรรม</h2>
            <p>
              คำประกาศเกี่ยวกับความเป็นส่วนตัว (Privacy Notice)
              ของระบบบริการดีพร้อม (DIPROM E-Services)
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-10 col-12">
          <div class="accordion faq-accordion" id="accordionPdpa">
            <div class="accordion-item border border-2 active">
              <h5 class="accordion-header" id="faq-1">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-main"
                  aria-expanded="true"
                >
                  ระบบบริการดีพร้อม (DIPROM E-Services)
                </button>
              </h5>
              <div
                id="collapse-main"
                class="accordion-collapse collapse show"
                aria-labelledby="faq-1"
                data-bs-parent="#accordionPdpa"
              >
                <div class="accordion-body">
                  &nbsp;&nbsp;คำประกาศเกี่ยวกับความเป็นส่วนตัว (“ประกาศ”)
                  นี้จัดทำขึ้นเพื่อให้ท่านซึ่งเป็นผู้ใช้งานระบบบริการดีพร้อม
                  (ซึ่งต่อไปในประกาศนี้ เรียกว่า “กิจกรรมการประมวลผล”)
                  ได้ทราบและเข้าใจรูปแบบการเก็บรวบรวม ใช้ และเปิดเผย
                  (“ประมวลผล”)
                  ข้อมูลส่วนบุคคลที่กระทรวงอุตสาหกรรมโดยกรมส่งเสริมอุตสาหกรรม
                  (ซึ่งต่อไปในประกาศนี้ เรียกว่า “กสอ.”)
                  ดำเนินการในฐานะผู้ควบคุมข้อมูลส่วนบุคคล
                  ที่เก็บรวบรวมจากท่านเพื่อการดำเนินการภายใต้กิจกรรมการประมวลผลนี้

                  <br />
                  &nbsp;&nbsp;กสอ. พัฒนาระบบบริการดีพร้อม (DIPROM E-Services)
                  สำหรับอำนวยความสะดวกและสนับสนุนผู้ประกอบการ SMEs วิสาหกิจชุมชน
                  และประชาชนทั่วไป
                  โดยผู้ใช้บริการต้องลงทะเบียนเพื่อขอรับชื่อผู้ใช้งานและรหัสผ่าน
                  (Username & Password) สำหรับเข้าใช้งานระบบสารสนเทศต่าง ๆ
                  ของหน่วยงานภายในสังกัด กสอ.
                  ด้วยระบบทะเบียนลูกค้ากระทรวงอุตสาหกรรม ทั้งนี้
                  เราดำเนินการเกี่ยวกับการเก็บรวบรวม ใช้
                  หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน ดังนี้
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-2">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-1"
                  aria-expanded="false"
                >
                  1. ฐานกฎหมายในการประมวลผลข้อมูลส่วนบุคคล
                </button>
              </h5>
              <div
                id="collapse-1"
                class="accordion-collapse collapse"
                aria-labelledby="faq-2"
                data-bs-parent="#accordionPdpa"
              >
                <div class="accordion-body">
                  กสอ.
                  ดำเนินการเก็บรวบรวมข้อมูลส่วนบุคคลของท่านภายใต้เหตุผลความจำเป็นเพื่อการปฏิบัติหน้าที่ตามกฎหมาย
                  ได้แก่ พ.ร.บ. การบริหารงานและการให้บริการภาครัฐผ่านระบบดิจิทัล
                  พ.ศ. 2562 ตามมาตรา 4
                  กำหนดให้การบริหารงานภาครัฐและการจัดทำบริการสาธารณะเป็นไปด้วยความสะดวก
                  รวดเร็ว มีประสิทธิภาพ
                  และตอบสนองต่กสอ.รให้บริการและการอำนวยความสะดวกแก่ประชาชน
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-3">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-2"
                  aria-expanded="false"
                >
                  2. วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน
                </button>
              </h5>
              <div
                id="collapse-2"
                class="accordion-collapse collapse"
                aria-labelledby="faq-3"
                data-bs-parent="#accordionPdpa"
              >
                <div class="accordion-body">
                  <p>
                    กสอ.
                    ดำเนินการเก็บรวบรวมข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์
                    ดังต่อไปนี้
                  </p>

                  <p>
                    1) เพื่อการลงทะเบียน บริการพิสูจน์ และการยืนยันตัวตน เช่น
                    เพื่อลงทะเบียนบริการแก่ท่าน พิสูจน์ ระบุ
                    และยืนยันท่านหรือตัวตนของท่าน ผู้มีอำนาจ หรือตัวแทนของท่าน
                    รวมถึงพิสูจน์ตัวตนหรือยืนยันตัวตนของท่านทางดิจิทัล
                    กระบวนการยืนยันตัวตนในรูปแบบดิจิทัล (Digital Identification)
                  </p>
                  <p>
                    2) เพื่อจัดหา ค้นหา แนะนำ หรือ
                    เสนอบริการที่เหมาะสมกับความต้องการของท่าน เช่น
                    แนะนำบริการที่ท่านอาจจะสนใจ
                  </p>
                  <p>
                    3) เพื่อปรับปรุงบริการ เช่น การประเมิน วิจัย วิเคราะห์
                    จัดทำแบบจำลองเพื่อการพัฒนาบริการ การสำรวจความพึงพอใจ
                  </p>
                  <p>
                    4) เพื่อการติดต่อสื่อสาร เช่น การติดต่อใด ๆ
                    ที่เกี่ยวเนื่องจากบริการของกระทรวง
                  </p>
                  <p>
                    5) เพื่อบริหารจัดการเว็บไซต์ แอปพลิเคชัน และ/หรือแพลตฟอร์ม
                    เช่น เพื่ออำนวยความสะดวกในการใช้งานเว็บไซต์ แอปพลิเคชัน
                    และ/หรือแพลตฟอร์ม
                  </p>
                  <p>
                    6) เพื่อจัดการด้านเทคโนโลยีสารสนเทศ เช่น
                    การจัดการระบบการสื่อสาร
                    การรักษาความปลอดภัยด้านเทคโนโลยีสารสนเทศ
                    การจัดการธุรกรรมเพื่อการปฏิบัติตามข้อกำหนด นโยบาย
                    และกระบวนการภายใน
                  </p>
                </div>
              </div>
            </div>

            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-4">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-3"
                  aria-expanded="false"
                >
                  3. ข้อมูลส่วนบุคคลที่เราเก็บรวบรวมและใช้
                </button>
              </h5>
              <div
                id="collapse-3"
                class="accordion-collapse collapse show"
                aria-labelledby="faq-4"
                data-bs-parent="#accordionPdpa"
              >
                <div class="accordion-body">
                  <p>
                    3.1. แหล่งข้อมูลและรายการข้อมูลส่วนบุคคลที่เก็บรวบรวม
                    มีดังนี้
                  </p>
                  <p>
                    &nbsp;&nbsp; &nbsp;&nbsp; 1.
                    เก็บข้อมูลจากท่านโดยตรงผ่านการลงทะเบียนระบบ
                  </p>

                  <div class="pricing-info mb-4">
                    <ul class="pricing-feature-list list-unstyled">
                      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;รายการข้อมูลส่วนบุคคล
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;หมายเลขบัตรประจำตัวประชาชน
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;ชื่อ
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;นามสกุล
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;วันเกิด
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;หมายเลขโทรศัพท์
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;ที่อยู่อีเมล
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;ไลน์ไอดี
                      </li>
                    </ul>
                  </div>
                  <p>
                    &nbsp;&nbsp; &nbsp;&nbsp; 2.
                    เก็บโดยการใช้เทคโนโลยีตรวจจับหรือติดตามพฤติกรรมการใช้งานของท่าน
                  </p>

                  <div class="pricing-info mb-4">
                    <ul class="pricing-feature-list list-unstyled">
                      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;รายการข้อมูลส่วนบุคคล
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;Cookie Data
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp; IP Address
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp; Logging
                      </li>
                    </ul>
                  </div>
                  <p>3.2. จุดประสงค์การใช้งานข้อมูลส่วนบุคคล</p>
                  <p>
                    &nbsp;&nbsp; &nbsp;&nbsp; 1.
                    เก็บข้อมูลจากท่านโดยตรงผ่านการลงทะเบียนระบบ
                  </p>
                  <div class="pricing-info mb-4">
                    <ul class="pricing-feature-list list-unstyled">
                      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;รายการข้อมูลส่วนบุคคลที่ใช้
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;หมายเลขบัตรประจำตัวประชาชน
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;ชื่อ
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;นามสกุล
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;วันเกิด
                      </li>
                    </ul>
                  </div>
                  <p>
                    &nbsp;&nbsp; &nbsp;&nbsp; 2.
                    เพื่อการติดต่อสื่อสารเกี่ยวกับระบบ เช่น
                    การประชาสัมพันธ์กิจกรรม/โครงการ
                  </p>
                  <div class="pricing-info mb-4">
                    <ul class="pricing-feature-list list-unstyled">
                      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;รายการข้อมูลส่วนบุคคลที่ใช้
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;หมายเลขโทรศัพท์
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;ที่อยู่อีเมล
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;ไลน์ไอดี
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-5">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-4"
                  aria-expanded="false"
                >
                  4. สิทธิตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
                </button>
              </h5>
              <div
                id="collapse-4"
                class="accordion-collapse collapse"
                aria-labelledby="faq-5"
                data-bs-parent="#accordionPdpa"
              >
                <div class="accordion-body">
                  โดยสามารถใช้สิทธิตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
                  2562 เมื่อบทบัญญัติ
                  ในส่วนที่เกี่ยวกับสิทธิของเจ้าของข้อมูลส่วนบุคคลมีผลบังคับใช้
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-6">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-5"
                  aria-expanded="false"
                >
                  5. ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล
                </button>
              </h5>
              <div
                id="collapse-5"
                class="accordion-collapse collapse"
                aria-labelledby="faq-6"
                data-bs-parent="#accordionPdpa"
              >
                <div class="accordion-body">
                  กระทรวงจะเก็บรักษาข้อมูลส่วนบุคคลของท่าน
                  เป็นระยะเวลาไม่น้อยกว่า 10 ปี
                  หรือเมื่อได้รับคำสั่งจากผู้มีอำนาจ
                  โดยระยเวลาดังกล่าวเป็นไปตามระเบียบสำนักนายกรัฐมนตรีว่าด้วยงานสารบรรณ
                  พ.ศ. 2526 และที่แก้ไขเพิ่มเติม (ฉบับที่ 2) พ.ศ. 2548
                  โดยเมื่อพ้นระยะเวลาดังกล่าวกระทรวงจะทำให้ข้อมูลส่วนบุคคลของท่านไม่สามารถระบุตัวตนได้และทำการลบออกจากหน่วยจัดเก็บข้อมูล
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-7">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-6"
                  aria-expanded="false"
                >
                  6. ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล
                </button>
              </h5>
              <div
                id="collapse-6"
                class="accordion-collapse collapse"
                aria-labelledby="faq-7"
                data-bs-parent="#accordionPdpa"
              >
                <div class="accordion-body">
                  กระทรวงมีความห่วงใยข้อมูลส่วนบุคคลของท่าน
                  จึงมีมาตรการในการรักษาความมั่นคงปลอดภัยข้อมูลส่วนบุคคลของท่านอย่างเหมาะสม
                  ทั้งในเชิงเทคนิคและการบริหารจัดการ
                  เพื่อป้องกันมิให้ข้อมูลสูญหาย หรือมีการเข้าถึง ทำลาย ใช้
                  เปลี่ยนแปลง แก้ไข หรือเปิดเผยข้อมูลส่วนบุคคลโดยไม่ได้รับอนุญาต
                  ซึ่งสอดคล้องกับนโยบายและแนวปฏิบัติด้านความมั่นคงปลอดภัยสารสนเทศ
                  (Information Security Policy)
                  โดยเฉพาะอย่างยิ่งข้อมูลหมายเลขหลังบัตรประจำตัวประชาชน (Laser
                  Code)
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-8">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-7"
                  aria-expanded="false"
                >
                  7. การมีส่วนร่วมของเจ้าของข้อมูลส่วนบุคคล
                </button>
              </h5>
              <div
                id="collapse-7"
                class="accordion-collapse collapse"
                aria-labelledby="faq-8"
                data-bs-parent="#accordionPdpa"
              >
                <div class="accordion-body">
                  เราอาจเปิดเผยข้อมูลส่วนบุคคลก็ต่อเมื่อได้รับคำร้องขอจากเจ้าของข้อมูลส่วนบุคคล
                  ผู้สืบสิทธิ์ ทายาท ผู้แทนโดยชอบธรรม
                  หรือผู้อนุบาลหรือผู้พิทักษ์ตามกฎหมาย
                  โดยส่งคำร้องขอผ่านทางจดหมายอิเล็กทรอนิกส์
                  service_ids@industry.go.th ในกรณีที่เจ้าของข้อมูล ผู้สืบสิทธิ์
                  ทายาท ผู้แทนโดยชอบธรรม
                  หรือผู้พิทักษ์ตามกฎหมายมีการคัดค้านการจัดเก็บ ความถูกต้อง
                  หรือการกระทำใด ๆ เช่น
                  การแจ้งดำเนินการปรับปรุงแก้ไขข้อมูลส่วนบุคคล
                  เราจะดำเนินการบันทึกหลักฐานคำคัดค้านดังกล่าวไว้เป็นหลักฐานด้วย
                  ทั้งนี้ เราอาจปฏิเสธสิทธิตามวรรคสองได้ตามกรณีที่มีกฎหมายกำหนด
                  หรือในกรณีที่ข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลถูกทำให้ไม่ปรากฏชื่อหรือสิ่งบอกลักษณะอันสามารถระบุตัวตนได้
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-9">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-5"
                  aria-expanded="false"
                >
                  8. การเข้าถึงข้อมูลส่วนบุคคล
                </button>
              </h5>
              <div
                id="collapse-8"
                class="accordion-collapse collapse"
                aria-labelledby="faq-9"
                data-bs-parent="#accordionPdpa"
              >
                <div class="accordion-body">
                  เราได้กำหนดให้เจ้าหน้าที่เฉพาะผู้ที่มีอำนาจหน้าที่เกี่ยวข้องในการจัดเก็บรวบรวม
                  ใช้
                  และเปิดเผยข้อมูลส่วนบุคคลของกิจกรรมการประมวลผลนี้เท่านั้นที่จะสามารถเข้าถึงข้อมูลส่วนบุคคลของท่านได้
                  โดยเราจะดำเนินการให้เจ้าหน้าที่ปฏิบัติตามประกาศนี้อย่างเคร่งครัด
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-10">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-8"
                  aria-expanded="false"
                >
                  9. การเปลี่ยนแปลงแก้ไขคำประกาศเกี่ยวกับความเป็นส่วนตัว
                </button>
              </h5>
              <div
                id="collapse-5"
                class="accordion-collapse collapse"
                aria-labelledby="faq-10"
                data-bs-parent="#accordionPdpa"
              >
                <div class="accordion-body">
                  กสอ. อาจพิจารณาปรับปรุง แก้ไข หรือเปลี่ยนแปลงประกาศนี้
                  ตามที่เห็นสมควร และจะทำการแจ้งให้ทราบผ่านช่องทางเว็บไซต์
                  โดยมีวันที่ของเวอร์ชั่นล่าสุดกำกับอยู่ตอนท้าย อย่างไรก็ดี
                  เราขอแนะนำให้ตรวจสอบเพื่อรับทราบประกาศฉบับใหม่อย่างสม่ำเสมอ
                  โดยเฉพาะก่อนที่จะทำการเปิดเผยข้อมูลส่วนบุคคล
                  การเข้าใช้งานระบบนี้ ถือเป็นการรับทราบตามข้อตกลงในประกาศนี้
                  ทั้งนี้
                  โปรดหยุดการใช้งานหากไม่เห็นด้วยกับข้อตกลงในประกาศฉบับนี้
                  โดยหากยังคงใช้งานต่อไปภายหลังจากที่ประกาศนี้มีการแก้ไขและนำขึ้นประกาศในช่องทางข้างต้นแล้ว
                  จะถือว่าได้รับทราบการเปลี่ยนแปลงดังกล่าวแล้ว
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-11">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-10"
                  aria-expanded="true"
                >
                  10. การติดต่อสอบถาม
                </button>
              </h5>
              <div
                id="collapse-10"
                class="accordion-collapse collapse show"
                aria-labelledby="faq-11"
                data-bs-parent="#accordionPdpa"
              >
                <div class="accordion-body">
                  <p>สามารถติดต่อสอบถามเกี่ยวกับประกาศฉบับนี้ได้ที่</p>
                  <p>10.1 ผู้ควบคุมข้อมูลส่วนบุคคล (Data Controller)</p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;(1) ชื่อ : กรมส่งเสริมอุตสาหกรรม
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;(2) สถานที่ติดต่อ : เลขที่ 75/6
                    ถนนพระรามที่ 6 ราชเทวี กรุงเทพฯ 10400
                  </p>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;(3) ช่องทางการติดต่อ :</p>
                  <!--<p>
                                โทรศัพท์ : 0 2430 6967
                            </p>
                            <p>
                                จดหมายอิเล็กทรอนิกส์ : service_ids@industry.go.th
                            </p>
                            <p>
                                Line : @iindustry
                            </p>
                            <p>
                                Facebook : iindustrymoi
                            </p>-->
                  <div class="pricing-info mb-4">
                    <ul class="pricing-feature-list list-unstyled">
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;โทรศัพท์ : 0 2430 6860
                      </li>
                      <li>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<i
                          class="fas fa-circle fa-2xs text-primary me-2"
                        ></i>
                        &nbsp;&nbsp;จดหมายอิเล็กทรอนิกส์ : webmaster@dip.go.th
                      </li>
                      <!--<li>&nbsp;&nbsp; &nbsp;&nbsp;<i class="fas fa-circle fa-2xs text-primary me-2"></i>  &nbsp;&nbsp;Line : @iindustry</li>
                                    <li>&nbsp;&nbsp; &nbsp;&nbsp;<i class="fas fa-circle fa-2xs text-primary me-2"></i>  &nbsp;&nbsp; Facebook : iindustrymoi</li>-->
                    </ul>
                  </div>
                  <p>
                    10.2 เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection
                    Officer: DPO)
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;(1) ชื่อ : กรมส่งเสริมอุตสาหกรรม
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;(2) สถานที่ติดต่อ :
                    กรมส่งเสริมอุตสาหกรรม เลขที่ 75/6 ถนนพระรามที่ 6 ราชเทวี
                    กรุงเทพฯ 10400
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;(3) ช่องทางการติดต่อ :
                    webmaster@dip.go.th
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="text-center mt-5">
          <a :href="`/pdpa.pdf`" class="btn btn-primary" target="_blank"
            >ดาวโหลดคำประกาศเกี่ยวกับความเป็นส่วนตัว (Privacy Notice) ฉบับเต็ม
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "SectionPdpa",
  props: {},
  data() {
    return {
      publicPath: process.env.BASE_URL
    };
  },
  created() {},
  mounted() {
    console.log("publicPath", process.env.BASE_URL);
  },
  methods: {},
};
</script>

<style></style>
