<template>
  <section class="image-feature pt-60 pb-120">
    <div class="container">
      <CoolLightBox :items="items" :index="index" @close="index = null">
      </CoolLightBox>
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-6">
          <div class="section-heading text-center">
            <!--<h4 class="h5 text-primary">Process</h4>-->
            <h2>แนะนำหน่วยงาน</h2>
            <!--<p>ขั้นตอนการใช้งานในระบบบริการดีพร้อม (DIPROM E-Services)</p>-->
          </div>
        </div>
      </div>

      <div class="row justify-content-between align-items-center">
        <div class="col-lg-6 col-12 order-lg-1">
          <div class="feature-img-content">
            <h3>ประวัติกรมส่งเสริมอุตสาหกรรม</h3>
            <p>
              ในอดีตกรมส่งเสริมอุตสาหกรรมมีฐานะเป็นหน่วยงานระดับกอง
              ภายใต้กองอุตสาหกรรม สังกัดกรมพาณิชย์ กระทรวงเศรษฐการ ต่อมาในปี
              พ.ศ. 2484 ถูกยกฐานะขึ้นเป็นกรมอุตสาหกรรม จากนั้นในปี พ.ศ. 2485
              ได้เปลี่ยนชื่อเป็น กรมส่งเสริมอุตสาหกรรม สังกัดกระทรวงอุตสาหกรรม
              โดยมีบทบาทสำคัญด้านการส่งเสริมอุตสาหกรรมที่จำเป็นของประเทศ
            </p>
            <p>
              แม้กรมส่งเสริมอุตสาหกรรมจะก่อตั้งขึ้นอย่างเป็นทางการในปี พ.ศ. 2485
              แต่บทบาทด้านการส่งเสริมอุตสาหกรรมได้เริ่มมาตั้งแต่ปี พ.ศ. 2479
              ซึ่งเป็นช่วงเวลาเดียวกับที่สงครามมหาเอเชียบูรพากำลังเริ่มต้นขึ้น
              ผลกระทบจากสงครามในครั้งนั้น
              ทำให้ประเทศไทยต้องประสบกับภาวะขาดแคลนสินค้า
              เพื่อบรรเทาวิกฤตดังกล่าว
              รัฐบาลจึงเข้ามาส่งเสริมการผลิตสินค้าอุปโภคบริโภคภายในประเทศ
              โดยมอบหมายให้กรมส่งเสริมอุตสาหกรรมเป็นหน่วยงานหลักในการส่งเสริมอุตสาหกรรมไทยให้เติบโตขึ้นจวบจนถึงปัจจุบัน
            </p>
            <p>
              ที่ผ่านมากรมส่งเสริมอุตสาหกรรมทำหน้าที่เสริมสร้างศักยภาพผู้ประกอบการภาคอุตสาหกรรมวิสาหกิจขนาดกลางและขนาดย่อม
              (Small and Medium Enterprises) และวิสาหกิจชุมชน (Micro
              Enterprises) ให้มีความเข้มแข็ง ท่ามกลางวิกฤตเศรษฐกิจ ภัยพิบัติ
              มาตรการทางการค้า ข้อกฎหมายใหม่ ๆ
              และเทคโนโลยีที่เจริญรุดหน้าอย่างรวดเร็ว
              ซึ่งหนึ่งในความภูมิใจสูงสุดของเจ้าหน้าที่กรมส่งเสริมอุตสาหกรรมคือ
              การเคียงข้าง พัฒนา และช่วยเหลือผู้ประกอบการในทุกสถานการณ์
              เพื่อให้ผู้ประกอบการเป็นพลังอันแข็งแกร่งในการขับเคลื่อนอุตสาหกรรมไทยให้ก้าวไกล
              สามารถแข่งขันและยืนหยัดบนเวทีระดับภูมิภาค และระดับโลกต่อไป
            </p>
            <p>
              ความสำเร็จของกรมส่งเสริมอุตสาหกรรมในวันนี้ เป็นผลจากภารกิจ
              “ริเริ่ม - ส่งเสริม - วิจัยและพัฒนา”
              ที่ทำมาอย่างต่อเนื่องและก่อเกิดผลงานที่เป็นรูปธรรม โดยงานสำคัญหลาย
              ๆ งานที่ริเริ่ม ส่งเสริม และพัฒนาการผลิตมากมาย
              ยังคงเป็นรากฐานในการพัฒนาอุตสาหกรรมของประเทศมาจนถึงทุกวันนี้
              รวมถึงงานด้านวิจัยพัฒนาที่เจ้าหน้าที่กรมส่งเสริมอุตสาหกรรมได้ค้นคว้า
              ทดลอง เพื่อค้นหาองค์ความรู้ใหม่ ๆ
              ในการเพิ่มผลผลิตให้แก่ผู้ประกอบการ
            </p>
            <p>
              เมื่อโลกในปัจจุบันหมุนไปอย่างรวดเร็ว
              อันเป็นผลมาจากเทคโนโลยีที่พัฒนาอย่างก้าวกระโดด
              ซึ่งการเปลี่ยนผ่านครั้งสำคัญจากอุตสาหกรรมที่ขับเคลื่อนโดยแรงงาน
              ไปสู่อุตสาหกรรมที่ขับเคลื่อนด้วยเทคโนโลยี ความคิดสร้างสรรค์
              และนวัตกรรม
              เป็นแรงกดดันให้ผู้ประกอบการทุกภาคส่วนต้องยกระดับขีดความสามารถในการแข่งขัน
              และปรับจุดยืนครั้งใหญ่
            </p>
            <p>
              เพื่อให้ภาคอุตสาหกรรมของประเทศสามารถเผชิญกับการเปลี่ยนผ่านครั้งนี้ได้อย่างเข้มแข็ง
              กรมส่งเสริมอุตสาหกรรมจึงได้ปรับโครงสร้างภายในองค์กรให้ทันสมัย
              โดยพัฒนาระบบงานด้านต่าง ๆ ให้มีประสิทธิภาพ เพื่อทำหน้าที่
              “พี่เลี้ยง” (Mentor) ของผู้ประกอบการได้อย่างสมบูรณ์แบบ
              และสร้างประโยชน์สูงสุดให้เกิดแก่ SMEs
            </p>
            <p>
              กว่า 7
              ทศวรรษที่กรมส่งเสริมอุตสาหกรรมยืนหยัดเคียงข้างผู้ประกอบการภาคอุตสาหกรรมมาทุกยุคทุกสมัย
              ก็ด้วยความมุ่งหวังที่จะนำพาอุตสาหกรรมไทยเดินหน้าต่อไปได้อย่าง
              “มั่นคง มั่งคั่ง และยั่งยืน”
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-12 order-lg-0">
          <div class="feature-img-holder mt-4 mt-lg-0 mt-xl-0">
            <img
              src="@/assets/img/company/DIP_500_714.png"
              class="img-fluid"
              alt="feature-image"
            />
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-12">
          <div class="section-heading text-center">
            <h5 class="h6 text-primary"></h5>
            <h2>TIMELINE</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="team-single-wrap mt-5 cursor-pointer" v-on:click="index = 0">
            <div class="team-img rounded-custom">
              <img
                src="@/assets/img/timeline/timeline 1.png"
                alt="team"
                class="img-fluid position-relative"
              />
            </div>
            <div class="team-info mt-4 text-center">
              <h5 class="h6 mb-1">พ.ศ. 2479 - 2492</h5>
              <p class="text-muted small mb-0">กรมส่งเสริมอุตสาหกรรม</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="team-single-wrap mt-5">
            <div class="team-img rounded-custom cursor-pointer" v-on:click="index = 1">
              <img
                src="@/assets/img/timeline/timeline 2.png"
                alt="team"
                class="img-fluid position-relative"
              />
            </div>
            <div class="team-info mt-4 text-center">
              <h5 class="h6 mb-1">พ.ศ. 2493 - 2519</h5>
              <p class="text-muted small mb-0">กรมส่งเสริมอุตสาหกรรม</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="team-single-wrap mt-5">
            <div class="team-img rounded-custom cursor-pointer" v-on:click="index = 2">
              <img
                src="@/assets/img/timeline/timeline 3.png"
                alt="team"
                class="img-fluid position-relative"
              />
            </div>
            <div class="team-info mt-4 text-center">
              <h5 class="h6 mb-1">พ.ศ. 2520 - 2534</h5>
              <p class="text-muted small mb-0">กรมส่งเสริมอุตสาหกรรม</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="team-single-wrap mt-5">
            <div class="team-img rounded-custom cursor-pointer" v-on:click="index = 3">
              <img
                src="@/assets/img/timeline/timeline 4.png"
                alt="team"
                class="img-fluid position-relative"
              />
            </div>
            <div class="team-info mt-4 text-center">
              <h5 class="h6 mb-1">พ.ศ. 2535 - 2544</h5>
              <p class="text-muted small mb-0">กรมส่งเสริมอุตสาหกรรม</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="team-single-wrap mt-5">
            <div class="team-img rounded-custom cursor-pointer" v-on:click="index = 4">
              <img
                src="@/assets/img/timeline/timeline 5.png"
                alt="team"
                class="img-fluid position-relative"
              />
            </div>
            <div class="team-info mt-4 text-center">
              <h5 class="h6 mb-1">พ.ศ. 2545 - 2549</h5>
              <p class="text-muted small mb-0">กรมส่งเสริมอุตสาหกรรม</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="team-single-wrap mt-5">
            <div class="team-img rounded-custom cursor-pointer" v-on:click="index = 5">
              <img
                src="@/assets/img/timeline/timeline 6.png"
                alt="team"
                class="img-fluid position-relative"
              />
            </div>
            <div class="team-info mt-4 text-center">
              <h5 class="h6 mb-1">พ.ศ. 2550 - 2561</h5>
              <p class="text-muted small mb-0">กรมส่งเสริมอุตสาหกรรม</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import img1 from "../../assets/img/timeline/timeline 1.png";
import img2 from "../../assets/img/timeline/timeline 2.png";
import img3 from "../../assets/img/timeline/timeline 3.png";
import img4 from "../../assets/img/timeline/timeline 4.png";
import img5 from "../../assets/img/timeline/timeline 5.png";
import img6 from "../../assets/img/timeline/timeline 6.png";

export default {
  name: "SectionAbout",
  props: {},
  data() {
    return {
      items: [
          img1,
          img2,
          img3,
          img4,
          img5,
          img6,
      ],
      index: null,
    };
  },
  created() {},
  mounted() {
    // console.log("window", );
    // this.items.forEach(e => {
    //   console.log(e);
    //   const blob = new Blob([e]);
    //   const objectUrl = URL.createObjectURL(blob);
    //   console.log("obj", objectUrl);
      
    // });
  },
  methods: {

  },
};
</script>

<style></style>
