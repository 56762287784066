<template>
  <section class="masonary-blog-section ptb-120 bg-light">
    <div class="container">
      <div class="row">
        <div class="col-8" style="margin: auto">
          <p for="Search" class="mb-1 text-center" style="font-size: x-large">
            ค้นหาข้อมูลข่าวสารและกิจกรรม
          </p>
          <div class="input-group mb-3">
            <select
              ref="RefSelectCate"
              class="form-control form-select select-custom"
              name="budget"
              id="budget"
              required=""
              v-model="filter"
              data-msg="Please select your budget."
              placeholder="กรุณาเลือกข้อมูล"
            >
              <option v-for="(item, i) in itemSelect" :value="item.id" :key="i">
                {{ item.val }}
              </option>
            </select>
            <input
              type="text"
              style="border-radius: 5px"
              class="form-control"
              required
              placeholder="ค้นหา"
              v-model="textSearch"
              @keyup.enter="OnSearch()"
            />
            <button
              type="submit"
              class="btn btn-primary"
              style="border-radius: 5px; margin-left: 10px"
              v-on:click="OnSearch()"
            >
              ค้นหา
            </button>
          </div>
          <SearchExample />
        </div>
      </div>
      <div class="row text-center" v-if="!hasData">
        <p class="no-data-custom">
          <i class="far fa-exclamation-circle"></i> Not found item.
        </p>
      </div>
      <!--pagination start-->
      <div
        class="row justify-content-end align-items-center mt-5"
        v-if="hasData"
      >
        <div class="col-auto my-1">
          <nav>
            <v-pagination
              v-model="currentPage"
              :classes="bootstrapPaginationClasses"
              :labels="customLabels"
              :page-count="totalPage"
              @change="OnChangePage(currentPage)"
            ></v-pagination>
          </nav>
        </div>
      </div>
      <!--pagination end-->

      <div class="row" v-if="hasData">
        <div
          class="col-lg-6 col-md-12"
          v-for="(item, index) in itemNewsData.TopTwoItem"
          :key="index"
        >
          <div class="single-article feature-article rounded-custom my-3">
            <a
              class="article-img cursor-custom"
              v-on:click="GoToDetail(item.Id)"
            >
              <img
                :src="
                  item.ImageFilesName.length > 0
                    ? item.ImageFilesName[0].Url
                    : '@/assets/img/blog/blog-10.jpg'
                "
                alt="article"
                class="img-fluid"
              />
            </a>
            <div class="article-content p-4">
              <div class="article-category mb-4 d-block">
                <i class="fa fa-eye" aria-hidden="true"></i>
                {{ item.TotalView }}
              </div>
              <a class="cursor-custom" v-on:click="GoToDetail(item.Id)">
                <h2 class="h5 article-title limit-1-line-text">
                  {{ item.Title }}
                </h2>
              </a>
              <p class="limit-1-line-text">
                {{ item.IntroText }}
              </p>
              <div class="d-flex align-items-center pt-4">
                <div class="avatar-info">
                  <span class="small fw-medium text-muted">
                    วันที่เผยแพร่ :
                    {{
                      item.PublicDate != null
                        ? moment(item.PublicDate, "DD-MM-YYYY").format(
                            "DD-MMM-YYYY"
                          )
                        : "-"
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="hasData">
        <div
          class="col-lg-4 col-md-6"
          v-for="(itemG, indexG) in itemNewsData.GeneralData"
          :key="indexG"
        >
          <div class="single-article rounded-custom my-3">
            <a
              class="article-img cursor-custom"
              v-on:click="GoToDetail(itemG.Id)"
            >
              <img
                :src="
                  itemG.ImageFilesName.length > 0
                    ? itemG.ImageFilesName[0].Url
                    : '@/assets/img/blog/blog-1.jpg'
                "
                alt="article"
                class="img-fluid"
              />
            </a>
            <div class="article-content p-4">
              <div class="article-category mb-4 d-block">
                <i class="fa fa-eye" aria-hidden="true"></i>
                {{ itemG.TotalView }}
              </div>
              <a class="cursor-custom" v-on:click="GoToDetail(itemG.Id)">
                <h2 class="h5 article-title limit-1-line-text">
                  {{ itemG.Title }}
                </h2>
              </a>
              <p class="limit-1-line-text">
                {{ itemG.IntroText }}
              </p>
              <div class="d-flex align-items-center pt-4">
                <div class="avatar-info">
                  <span class="small fw-medium text-muted">
                    วันที่เผยแพร่ :
                    {{
                      itemG.PublicDate != null
                        ? moment(itemG.PublicDate, "DD-MM-YYYY").format(
                            "DD-MMM-YYYY"
                          )
                        : "-"
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--pagination start-->
      <div
        class="row justify-content-center align-items-center mt-5"
        v-if="hasData"
      >
        <div class="col-auto my-1">
          <nav>
            <v-pagination
              v-model="currentPage"
              :classes="bootstrapPaginationClasses"
              :labels="customLabels"
              :page-count="totalPage"
              @change="OnChangePage(currentPage)"
            ></v-pagination>
          </nav>
        </div>
      </div>
      <!--pagination end-->
      <!-- <Loading /> -->
    </div>
  </section>
</template>
<style>
.select-custom {
  margin-right: 10px;
  border-radius: 5px !important;
  max-width: 20%;
}
.back-to-top-link {
  pointer-events: all;
  top: calc(100vh - 5rem);
}
.no-data-custom {
  margin: auto;
  padding-top: 200px;
  height: 400px;
  font-size: x-large;
}
</style>

<script>
import axios from "axios";
import store from "../../store";
import SearchExample from "../../components/common/SearchExample.vue";
import Loading from "@/components/loading/Loading.vue";
import vPagination from "vue-plain-pagination";
import moment from "moment";

const GetAllArticleURI = `${store.getters.URL}/NewsAndActivity/get-news-by-param-new`;
const UpdateViewNewURI = `${store.getters.URL}/NewsAndActivity/update-view-news`;
const GetMainCategoryURI = `${store.getters.URL}/NewsAndActivity/get-main-category`;

export default {
  components: {
    SearchExample,
    vPagination,
    Loading,
  },
  props: {
    topic: {
      type: Number,
      default: 0,
    },
  },
  setup() {},
  mounted() {
    this.SetDefault();
    this.LoadItemSelect();
    this.CheckParam();
    this.GetNews();
    // console.log('New Type', store.getters.NewsDetail)
  },
  data() {
    return {
      moment,
      textSearch: null,
      hasData: false,
      itemSelect: [{ id: 0, val: "ทั้งหมด" }],
      filter: 0,
      itemNewsData: {
        itemAll: [],
        TopTwoItem: [],
        GeneralData: [],
      },
      currentPage: 1,
      recordsPerPage: 11,
      totalPage: 1,
      totalRecord: 0,
      bootstrapPaginationClasses: {
        ul: "pagination rounded mb-0",
        li: "page-item",
        liActive: "active",
        liDisable: "disabled",
        button: "page-link",
      },
      customLabels: {
        first: false,
        prev: "Previous",
        next: "Next",
        last: false,
      },
    };
  },
  methods: {
    SetDefault() {
      this.textSearch = null;
      this.hasData = false;
      this.filter = 0;
      this.itemNewsData.itemAll = [];
      this.itemNewsData.TopTwoItem = [];
      this.itemNewsData.GeneralData = [];
    },
    SetDefaultDataForShow() {
      this.itemNewsData.TopTwoItem = [];
      this.itemNewsData.GeneralData = [];
    },
    CheckParam() {
      if (
        this.$route.params.textSearch != null ||
        this.$route.params.textSearch != undefined ||
        this.$route.params.textSearch != ""
      ) {
        this.textSearch = this.$route.params.textSearch;
      }
    },
    GetMainCategory() {
      axios
        .get(GetMainCategoryURI, { params: { subWebsiteId: 3 } })
        .then((response) => {
          // console.log("response GetMainCategoryURI", response.data.Data);
          this.itemSelect = [];
          this.itemSelect.push({ id: 0, val: "ทั้งหมด" });
          for (var i = 0; i < response.data.Data.length; i++) {
            this.itemSelect.push({
              id: response.data.Data[i].id,
              val: response.data.Data[i].name_th,
            });
          }
          // console.log("itemSelect", this.itemSelect)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    LoadItemSelect() {
      const newDetail = store.getters.NewsDetail;
      //console.log('newDetail', newDetail)
      if (newDetail.Type == 3) {
        this.GetMainCategory();
        this.$refs.RefSelectCate.style.display = "";
      } else {
        this.$refs.RefSelectCate.style.display = "none";
      }
    },
    OnSearch() {
      this.GetNews();
    },
    GetNews() {
      const newDetail = store.getters.NewsDetail;
      //console.log("newDetail", newDetail);
      //this.SetDefaultDataForShow();
      let temp = {
        SubWebsiteId: newDetail.Type,
        SearchText: this.textSearch,
        CategoryId: newDetail.CateId,
        ChildCategoryId: newDetail.SubCateId,
        PageNumber: !this.currentPage ? 1 : this.currentPage,
        PageSize: 11,
      };
      //console.log("filter", this.filter);
      if (newDetail.Type == 3) {
        temp.CategoryId = this.filter;
      }

      axios
        .post(GetAllArticleURI, temp)
        .then((response) => {
          console.log("response New", response.data.Data);
          this.itemNewsData.itemAll = response.data.Data?.Articles;
          this.totalRecord = response.data.Data?.TotalCount;
          // this.itemNewsData.TopTwoItem = response.data.Data.slice(0, 2)
          // response.data.Data.splice(0, 2);
          // this.itemNewsData.GeneralData = response.data.Data;
          // console.log(this.itemNewsData);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (this.itemNewsData.itemAll.length > 0) {
            this.hasData = true;
          } else {
            this.hasData = false;
          }
          this.OnChangeData(this.currentPage);
        });
    },
    BindDataToShow(data, count) {
      //console.log("BindDataToShow data", data);
      //console.log("BindDataToShow count", count);

      if (count <= 2) {
        this.itemNewsData.TopTwoItem.push(data);
      } else {
        this.itemNewsData.GeneralData.push(data);
      }
    },
    GoToDetail(idNews) {
      store.commit("SetNewsId", idNews);

      axios
        .put(`${UpdateViewNewURI}?id=${idNews}`)
        .then((response) => {
          if (response.status == 200) {
            this.$router.push({ name: "NewsDetails" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    OnPrevPage() {
      let tempCurrentPage = this.currentPage;
      if (tempCurrentPage > 1) {
        tempCurrentPage--;
        this.OnChangePage(tempCurrentPage);
      }
    },
    OnNextPage() {
      let tempCurrentPage = this.currentPage;
      if (tempCurrentPage < this.OnNumPages()) {
        tempCurrentPage++;
        this.OnChangePage(tempCurrentPage);
      }
    },
    OnChangeData(page) {
      //console.log("OnChangeData", page);
      if (page < 1) page = 1;
      if (page > this.OnNumPages()) page = this.OnNumPages();
      this.currentPage = page;
      let count = 1;
      this.SetDefaultDataForShow();
      for (
        var i = 0;
        i < page * this.recordsPerPage && i < this.itemNewsData.itemAll.length;
        i++
      ) {
        this.BindDataToShow(this.itemNewsData.itemAll[i], count);
        count++;
      }
      this.totalPage = this.OnNumPages();

      window.scrollTo({
        top: 300,
        behavior: "smooth",
      });
    },
    OnChangePage(page) {
      //console.log("OnChangePage", page);
      this.GetNews();
    },
    OnNumPages() {
      return Math.ceil(this.totalRecord / this.recordsPerPage);
    },
  },
  watch: {
    $route() {
      this.SetDefault();
      this.LoadItemSelect();
      this.CheckParam();
      this.GetNews();
    },
  },
};
</script>
