<template>
  <div class="main-wrapper">
    <Navbar />
    <SectionPdpa />
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/footer/Footer.vue";
import Navbar from "../../components/nav/Navbar.vue";
import SectionPdpa from "../../components/MainComponents/SectionPdpa.vue";

export default {
  components: { Navbar, SectionPdpa, Footer },
};
</script>
