<template>
  <section id="adviseSection" class="work-process ptb-60">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-6">
          <div class="section-heading text-center">
            <!--<h4 class="h5 text-primary">Process</h4>-->
            <h2>แนะนำการใช้งาน</h2>
            <p>ขั้นตอนการใช้งานในระบบบริการดีพร้อม (DIPROM E-Services)</p>
          </div>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-md-6 col-lg-3">
          <div
            class="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-3 mb-lg-0"
          >
            <div
              class="process-icon border border-light bg-custom-light rounded-custom p-3"
            >
              <span class="h2 mb-0 text-primary fw-bold">1</span>
            </div>
            <h3 class="h5">สมัครสมาชิก</h3>
            <p class="mb-0">
              กรอกเลขบัตรประจำตัวประชาชน 13 หลัก และเลือกช่องทางการยืนยันตัวตน
            </p>
          </div>
        </div>
        <div class="dots-line first"></div>
        <div class="col-md-6 col-lg-3">
          <div
            class="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-3 mb-lg-0"
          >
            <div
              class="process-icon border border-light bg-custom-light rounded-custom p-3"
            >
              <span class="h2 mb-0 text-primary fw-bold">2</span>
            </div>
            <h3 class="h5">เข้าสู่ระบบบริการดีพร้อม</h3>
            <p class="mb-0">กรอกเลขบัตรประจำตัวประชาชน 13 หลักและรหัสผ่าน</p>
          </div>
        </div>
        <div class="dots-line first"></div>
        <div class="col-md-6 col-lg-3">
          <div
            class="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-3 mb-lg-0 mb-md-0"
          >
            <div
              class="process-icon border border-light bg-custom-light rounded-custom p-3"
            >
              <span class="h2 mb-0 text-primary fw-bold">3</span>
            </div>
            <h3 class="h5">จัดการข้อมูลผู้ใช้งาน</h3>
            <p class="mb-0">ข้อมูลบุคคล ข้อมูลที่ปรึกษา ข้อมูลการประกอบการ</p>
          </div>
        </div>
        <div class="dots-line first"></div>
        <div class="col-md-6 col-lg-3">
          <div
            class="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-0"
          >
            <div
              class="process-icon border border-light bg-custom-light rounded-custom p-3"
            >
              <span class="h2 mb-0 text-primary fw-bold">4</span>
            </div>
            <h3 class="h5">เลือกบริการ</h3>
            <p class="mb-0">
              เลือกบริการหรือสมัครเข้าร่วมกิจกรรมของกรมส่งเสริมอุตสาหกรรม
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <video ref="vidRef" id="vid" playsinline controls autoplay loop>
          <source src="@/assets/video/DIPROM_ep.1.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  </section>
</template>
<script>
import store from "../../store";

export default {
  components: {},
  data() {
    return {
    };
  },
  mounted() {
    this.AutoPlay();
  },
  methods: {
    AutoPlay() {
      //this.$refs.vid.muted = false;
      //this.$refs.vid.played.start(1);
      // console.log("aaaa", this.$refs);
      // console.log("daaa", this.$refs.vid.buffered);
      // console.log("ff", document.getElementById("vid"));
      // var video = document.getElementById("vid");
      // video.play();
      // video.muted = false;
      setTimeout(() => {
        var video = document.getElementById("vid");
        //video.muted = false;
      }, 2000);
    },
  },
};
</script>
