import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

var ApisURL= "https://apicustomer.diprom.go.th" ;
const DiPromCustomerUrl = "https://customer.diprom.go.th/#/sign-in";

if (
  window.location.origin == "http://localhost:8080" ||
  window.location.origin == "https://localhost:8080" 
) {
  ApisURL = "https://apicustomer.diprom.go.th";
}
const state = {
    URL: ApisURL,
    CustomerUrl: DiPromCustomerUrl,
    NewsDetail:{
      Type:0,
      CateId:0,
      SubCateId:0,
      Id: 0
    },
    SubWebSite: [],
    CategoryData:[],
  };
const mutations = {
  SetNewsDetail(state, type){
    state.NewsDetail.Type = type;
  },
  SetNewsId(state, id){
    state.NewsDetail.Id = id;
  },
  SetNewsCateId(state, id){
    state.NewsDetail.CateId = id;
  },
  SetNewSubCateId(state, id){
    state.NewsDetail.SubCateId = id;
  },
  SetSubWebSite(state, val){
    state.SubWebSite = val
  },
  SetCategoryData(state, val){
    state.CategoryData = val
  },
};
export default new Vuex.Store({
  state,
  mutations,
  getters: {
    URL: (state) => state.URL,
    CustomerUrl: (state) => state.CustomerUrl,
    NewsDetail: (state) => state.NewsDetail,
    SubWebSite: (state) => state.SubWebSite,
    CategoryData: (state) => state.CategoryData,
  },
});